@import "type-01";
@import "type-02";
@import "type-03";
@import "customer-testimonials";
@import "header-cta";
@import "news";
@import "contact-cta";
@import "header-hr-text";
@import "faq";
@import "header-and-paragraph-grid";
@import "video-embed";

.layout-part {

	.container {
		position: relative;
	}
}
