.layout-part.layout-news {

	//background-color: $light-bg-color;
	
	.container {
		padding-top: rem(128);
		padding-bottom: rem(224);

		@include media($mobile) {
			padding-top: rem(32);
			padding-bottom: rem(96);
		}

		.column {
			@include span-columns(5);

			&:first-child {
				@include shift(1);
			}

			@include media($mobile) {
				@include span-columns(12);

				&:first-child {
					@include shift(0);
				}
			}

			h2 {
				margin-bottom: rem(8);
			}

			span.subheader {
				display: block;
				margin-bottom: rem(48);
			}

			.item {
				margin-bottom: rem(48);

				.read-more {
					white-space: nowrap;
				}
				.item-title a {
					color: #292d33;
				}
			}
		}
	}

}