.dropdown {
	.rtl & {
		* {
			unicode-bidi: bidi-override !important;
		}
		.input {
			.special,
			.category {
				left: 1.5rem;
				right: auto;
			}
		}
	}

	position: relative;
	width: 100%;
	height: rem(72);
	margin-bottom: rem(12);

	@include media($mobile) {
		font-size: 17px;
	}

	.input {
		border: 1px solid rgba(215, 215, 215, 0.65);
		border-right: none;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: rem(72);
		//background-color: #ffd;

		.suggestion {
			position: absolute;
			line-height: rem(30);
			padding: rem(22) rem(24) rem(18) rem(24);

			.beginning {
				visibility: hidden;
				//background-color: #f0f;
			}

			.end {
				padding: 0;
				//background-color: #0ff;
			}
		}

		input, .valuebox {
			width: 100%;
			//height: rem(70);
			line-height: rem(30);
			border: 0;
			padding: rem(22) rem(24) rem(18) rem(24);
			background-color: transparent;

			@include transition(padding-left 0.1s ease-in-out);
		}

		.valuebox {
			color: $dark-font-color;
			cursor: default;
		}

		.category {
			color: $gold;
			position: absolute;
			right: rem(24);
			top: rem(22);
			line-height: rem(30);
		}

		.special {
			color: inherit;
			position: absolute;
			right: rem(24);
			top: rem(22);
			line-height: rem(30);
		}
		&.modal {
			position: inherit;
			border: 0;
			input {
				padding: 11px;
				background: #f6f8fa;
				margin-left: 1rem;
				width: 91%;
				border-radius: 8px;
				height: 50px;
				border: 0;
			}
		}

	}


	.button {
		position: absolute;
		width: rem(72);
		height: 100%;
		right: 0;
		border: 1px solid rgba(215, 215, 215, 0.65);
		//border-left: 1px solid rgba(215, 215, 215, 0.65);

		cursor: pointer;
		
		.icon {
			width: 100%;
			height: 100%;

			background-image: url('img/arrow-down-icon.svg');
			background-size: 16px 9px;
			background-position: center;
			background-repeat: no-repeat;
		}
	}

	&.search .button {
		background-color: $red;
		border: none;

		.icon {
			background-image: url('img/search-icon.svg');
			background-size: auto;
		}
	}

	/*
	.arrow {
			position: absolute;
			cursor: pointer;
			width: rem(64);
			height: rem(64);
			background-color: $red;
			right: 0;
			background-image: url('img/search-icon.svg');
			background-repeat: no-repeat;
			background-position: center;
		}
	*/

	&.loading {
		background-image: url('img/loader_white.gif');
		background-size: 16px 16px;
		background-repeat: no-repeat;

		background-position: rem(24) center;

		input, .valuebox {
			padding-left: rem(48);
		}
	}

	&.disabled {
		opacity: 0.8;

		input {
			@include placeholder {
				color: $light-font-color;
			}
		}

		.arrow {
			cursor: inherit;
		}

		.icon {
			opacity: 0.2;
		}
	}

	.list {
		position: absolute;
		background-color: #fff;
		border: 1px solid rgba(215, 215, 215, 0.65);
		border-top: none;
		top: rem(72);
		width: 100%;
		z-index: 11;
		max-height: rem(256);
		overflow-y: auto;

		box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);

		@include media($mobile) {
			position: fixed;
			top: 0;
			left: 0;
			max-height: 100%;
			height: 100%;
		}

		li {
			padding: rem(15);
			@include media($mobile) {
				padding: rem(20);
			}
			padding-top: rem(24);
			cursor: pointer;
			color: #222;

			&.selected,
			&:hover {
				background-color: $light-bg-color;
			}

			span.category {
				color: $gold;
				float: right;
			}

			span.special {
				color: inherit;
				float: right;
				.rtl & {
					margin: 0 0 0 1em;
				}
			}

			&.divider {
				cursor: inherit;
				
				&:hover {
					background-color: inherit;
				}

				div {
					width: 100%;
					height: 1px;
					@media screen and (min-width: 481px) {
						background-color: rgba(215, 215, 215, 0.65);
					}

				}
			}
		}
		.modal-no-result {
			display: none;
			@include media($mobile) {
				visibility: inherit;
				display: flex;
				width: 100%;
				height: 20%;
				justify-content: center;
				align-items: center;
			}

		}

	}

	.list li.divider,
	.divider_possible_matches {
		padding: 1em 1em 0 1em;
		cursor: inherit;
		color: #A3A3B4;
		/*color: #7e7e94;*/
		div {
			width: 100%;
			/*height: 1px;*/
			/*background-color: rgba(215, 215, 215, 0.65);*/
			text-transform: uppercase;
			/*font-family: Arial;*/
			font-weight: 900;
			font-size: 0.6em;
			letter-spacing: 4px;
			@include media($mobile) {
				margin: 0 0 1.5em 0.2em;
				padding: 0;
				text-transform: none;
				font-size: 0.8em;
				letter-spacing: 0px;
				color: $modal-font-dark;
			}

		}
	}
	&.isflag {
		form {
			position: relative;
		}
		&[data=countries]:not(.open) .form-search > input[type=text]:not(.noFlag) {
			padding-left: rem(64);
			.rtl & {
				padding-right: rem(64);
				padding-left: rem(24);
			}
		}
		&[data=countries] {
			.flag_country {
				display: block;
				position: absolute;
				left: rem(24);
				display: block;
				width: rem(24);
				height: rem(16);
				/*background-image: url(img/empty.png);*/
				background-repeat: no-repeat;
				background-size: 100% 100%;
				border: 1px solid #ededed;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 3px;
				.rtl & {
					right: rem(24);
					left: auto;
				}
			}

			.form-search .flag_country {
				top: 50%;
				margin-top: rem(-8);

				body.prices-full-numbers-list & {
					left: rem(32);
					margin-top: -0.6rem;
				}

			}
			.list li {
				position: relative;
				padding-top: rem(24);
				padding-left: rem(64);
				background-repeat: no-repeat;
				.rtl & {
					padding-left: rem(24);
					padding-right: rem(64);
				}
			}
		}
	}
	.flag_country,
	&.open[data=countries] .form-search .flag_country {
		display: none;
	}

	.dropdown-modal {
		display: none;
		@include media($mobile) {
			display: contents;
			.dropdown-modal-header {
				display: flex;
				justify-content: space-between;
				margin: 1rem 1rem 1rem 1.3rem;
				font-size: 20px;
				color: $modal-font-dark;
				font-weight: 600;

				.dropdown-modal-button-close {
					cursor: pointer;
					.cross-close {
						transform: rotate(45deg);
						font-size: 30px;
						&:before {
							content: '+';

						}
					}
				}
			}
		}
	}


}
.spinner {
  position: absolute;
  /* WDP-765 Changes */
  bottom: 200px !important;
  left: 0;
  right: 0;
}
