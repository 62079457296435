.about {
	.top.wrapper {
		background-image: url('img/page-headers/about.png');
	}

	.enablers {
		background-color: $light-bg-color;

		.container {
			padding: rem(224) 0;

			@include media($mobile) {
				padding: rem(64) 0;
			}
		}

		.background-image {
			@include shift-pos-right(7 of 12);

			@include media($mobile) {
				display: none;
			}
		}

		.content-text {
			@include span-columns(4);
			@include shift(1);

			@include media($mobile) {
				@include span-columns(12);
				@include shift(0);
			}
		}
	}

	.mobile-background-image {
		height: rem(360);
	}

	.video {
		//@include span-columns(8 of 12);

		text-align: center;

		iframe {
			width: 100%;
			display: inline-block;
		}
	}

	.team-photo {
		height: rem(384);

		@include media($mobile) {
			height: rem(128);
		}
	}
}