.flag {
	&.aq, &.country_aq { background-image:url('img/flags/aq.png'); }
	&.en, &.country_us, &.country_um { background-image:url('img/flags/us.png'); }
	&.de, &.country_de { background-image:url('img/flags/de.png'); }
	&.sv, &.country_se { background-image:url('img/flags/se.png'); }

	&.ad, &.country_ad { background-image: url('img/flags/ad.png'); }
	&.ae, &.country_ae { background-image: url('img/flags/ae.png'); }
	&.af, &.country_af { background-image: url('img/flags/af.png'); }
	&.ag, &.country_ag { background-image: url('img/flags/ag.png'); }
	&.ai, &.country_ai { background-image: url('img/flags/ai.png'); }
	&.al, &.country_al { background-image: url('img/flags/al.png'); }
	&.am, &.country_am { background-image: url('img/flags/am.png'); }
	&.an, &.country_an { background-image: url('img/flags/an.png'); }

	&.ao, &.country_ao { background-image: url('img/flags/ao.png'); }
	&.ar, &.country_ar { background-image: url('img/flags/ar.png'); }
	&.as, &.country_as { background-image: url('img/flags/as.png'); }
	&.at, &.country_at { background-image: url('img/flags/at.png'); }
	&.au, &.country_au { background-image: url('img/flags/au.png'); }
	&.aw, &.country_aw { background-image: url('img/flags/aw.png'); }
	&.az, &.country_az { background-image: url('img/flags/az.png'); }
	&.ba, &.country_ba { background-image: url('img/flags/ba.png'); }
	&.bb, &.country_bb { background-image: url('img/flags/bb.png'); }
	&.bd, &.country_bd { background-image: url('img/flags/bd.png'); }
	&.be, &.country_be { background-image: url('img/flags/be.png'); }
	&.bf, &.country_bf { background-image: url('img/flags/bf.png'); }
	&.bg, &.country_bg { background-image: url('img/flags/bg.png'); }
	&.bh, &.country_bh { background-image: url('img/flags/bh.png'); }
	&.bi, &.country_bi { background-image: url('img/flags/bi.png'); }
	&.bj, &.country_bj { background-image: url('img/flags/bj.png'); }
	&.bm, &.country_bm { background-image: url('img/flags/bm.png'); }
	&.bn, &.country_bn { background-image: url('img/flags/bn.png'); }
	&.bo, &.country_bo { background-image: url('img/flags/bo.png'); }
	&.br, &.country_br { background-image: url('img/flags/br.png'); }
	&.bs, &.country_bs { background-image: url('img/flags/bs.png'); }
	&.bv, &.country_bv { background-image: url('img/flags/bv.png'); }
	&.bw, &.country_bw { background-image: url('img/flags/bw.png'); }
	&.bt, &.country_bt { background-image: url('img/flags/bt.png'); }
	&.by, &.country_by { background-image: url('img/flags/by.png'); }
	&.bz, &.country_bz { background-image: url('img/flags/bz.png'); }
	&.ca, &.country_ca { background-image: url('img/flags/ca.png'); }
	&.cc, &.country_cc { background-image: url('img/flags/cc.png'); }
	&.cd, &.country_cd { background-image: url('img/flags/cd.png'); }
	&.cf, &.country_cf { background-image: url('img/flags/cf.png'); }
	&.cg, &.country_cg { background-image: url('img/flags/cg.png'); }
	&.ch, &.country_ch { background-image: url('img/flags/ch.png'); }
	&.ci, &.country_ci { background-image: url('img/flags/ci.png'); }
	&.ck, &.country_ck { background-image: url('img/flags/ck.png'); }
	&.cl, &.country_cl { background-image: url('img/flags/cl.png'); }
	&.cm, &.country_cm { background-image: url('img/flags/cm.png'); }
	&.cn, &.zh-hans, &.country_cn { background-image: url('img/flags/cn.png'); }
	&.co, &.country_co { background-image: url('img/flags/co.png'); }
	&.cr, &.country_cr { background-image: url('img/flags/cr.png'); }
	&.cu, &.country_cu { background-image: url('img/flags/cu.png'); }
	&.cv, &.country_cv { background-image: url('img/flags/cv.png'); }
	&.cx, &.country_cx { background-image: url('img/flags/cx.png'); }
	&.cy, &.country_cy { background-image: url('img/flags/cy.png'); }
	&.cz, &.country_cz { background-image: url('img/flags/cz.png'); }
	&.dj, &.country_dj { background-image: url('img/flags/dj.png'); }
	&.dk, &.country_dk { background-image: url('img/flags/dk.png'); }
	&.dm, &.country_dm { background-image: url('img/flags/dm.png'); }
	&.do, &.country_do { background-image: url('img/flags/do.png'); }
	&.dz, &.country_dz { background-image: url('img/flags/dz.png'); }
	&.ec, &.country_ec { background-image: url('img/flags/ec.png'); }
	&.ee, &.country_ee { background-image: url('img/flags/ee.png'); }
	&.eg, &.country_eg { background-image: url('img/flags/eg.png'); }
	&.eh, &.country_eh { background-image: url('img/flags/eh.png'); }
	&.er, &.country_er { background-image: url('img/flags/er.png'); }
	&.es, &.country_es { background-image: url('img/flags/es.png'); }
	&.et, &.country_et { background-image: url('img/flags/et.png'); }
	&.fi, &.country_fi { background-image: url('img/flags/fi.png'); }
	&.fj, &.country_fj { background-image: url('img/flags/fj.png'); }
	&.fk, &.country_fk { background-image: url('img/flags/fk.png'); }
	&.fm, &.country_fm { background-image: url('img/flags/fm.png'); }
	&.fo, &.country_fo { background-image: url('img/flags/fo.png'); }
	&.fr, &.country_fr { background-image: url('img/flags/fr.png'); }
	&.ga, &.country_ga { background-image: url('img/flags/ga.png'); }
	&.gb, &.country_gb { background-image: url('img/flags/gb.png'); }
	&.gd, &.country_gd { background-image: url('img/flags/gd.png'); }
	&.ge, &.country_ge { background-image: url('img/flags/ge.png'); }
	&.gf, &.country_gf { background-image: url('img/flags/gf.png'); }
	&.gh, &.country_gh { background-image: url('img/flags/gh.png'); }
	&.gi, &.country_gi { background-image: url('img/flags/gi.png'); }
	&.gl, &.country_gl { background-image: url('img/flags/gl.png'); }
	&.GLO, &.country_GLO { background-image: url('img/flags/glo.png'); }
	&.gm, &.country_gm { background-image: url('img/flags/gm.png'); }
	&.gn, &.country_gn { background-image: url('img/flags/gn.png'); }
	&.gq, &.country_gq { background-image: url('img/flags/gq.png'); }
	&.gp, &.country_gp { background-image: url('img/flags/gp.png'); }
	&.gr, &.el, &.country_gr, &.country_el { background-image: url('img/flags/gr.png'); }
	&.gs, &.country_gs { background-image: url('img/flags/gs.png'); }
	&.gt, &.country_gt { background-image: url('img/flags/gt.png'); }
	&.gu, &.country_gu { background-image: url('img/flags/gu.png'); }
	&.gw, &.country_gw { background-image: url('img/flags/gw.png'); }
	&.gy, &.country_gy { background-image: url('img/flags/gy.png'); }
	&.hk, &.country_hk { background-image: url('img/flags/hk.png'); }
	&.hm, &.country_hm { background-image: url('img/flags/hm.png'); }
	&.hn, &.country_hn { background-image: url('img/flags/hn.png'); }
	&.hr, &.country_hr { background-image: url('img/flags/hr.png'); }
	&.ht, &.country_ht { background-image: url('img/flags/ht.png'); }
	&.hu, &.country_hu { background-image: url('img/flags/hu.png'); }
	&.id, &.country_id { background-image: url('img/flags/id.png'); }
	&.ie, &.country_ie { background-image: url('img/flags/ie.png'); }
	&.il, &.he, &.country_il, &.country_he { background-image: url('img/flags/il.png'); }
	&.in, &.country_in { background-image: url('img/flags/in.png'); }
	&.io, &.country_io { background-image: url('img/flags/io.png'); }
	&.iq, &.country_iq { background-image: url('img/flags/iq.png'); }
	&.ir, &.country_ir { background-image: url('img/flags/ir.png'); }
	&.is, &.country_is { background-image: url('img/flags/is.png'); }
	&.it, &.country_it { background-image: url('img/flags/it.png'); }
	&.jm, &.country_jm { background-image: url('img/flags/jm.png'); }
	&.jo, &.country_jo { background-image: url('img/flags/jo.png'); }
	&.jp, &.ja, &.country_jp, &.country_ja { background-image: url('img/flags/jp.png'); }
	&.ke, &.country_ke { background-image: url('img/flags/ke.png'); }
	&.kg, &.country_kg { background-image: url('img/flags/kg.png'); }
	&.kh, &.country_kh { background-image: url('img/flags/kh.png'); }
	&.ki, &.country_ki { background-image: url('img/flags/ki.png'); }
	&.km, &.country_km { background-image: url('img/flags/km.png'); }
	&.kn, &.country_kn { background-image: url('img/flags/kn.png'); }
	&.kp, &.country_kp { background-image: url('img/flags/kp.png'); }
	&.kr, &.country_kr { background-image: url('img/flags/kr.png'); }
	&.kw, &.country_kw { background-image: url('img/flags/kw.png'); }
	&.ky, &.country_ky { background-image: url('img/flags/ky.png'); }
	&.kz, &.country_kz { background-image: url('img/flags/kz.png'); }
	&.la, &.country_la { background-image: url('img/flags/la.png'); }
	&.lb, &.country_lb { background-image: url('img/flags/lb.png'); }
	&.lc, &.country_lc { background-image: url('img/flags/lc.png'); }
	&.li, &.country_li { background-image: url('img/flags/li.png'); }
	&.lk, &.country_lk { background-image: url('img/flags/lk.png'); }
	&.lr, &.country_lr { background-image: url('img/flags/lr.png'); }
	&.ls, &.country_ls { background-image: url('img/flags/ls.png'); }
	&.lt, &.country_lt { background-image: url('img/flags/lt.png'); }
	&.lu, &.country_lu { background-image: url('img/flags/lu.png'); }
	&.lv, &.country_lv { background-image: url('img/flags/lv.png'); }
	&.ly, &.country_ly { background-image: url('img/flags/ly.png'); }
	&.ma, &.country_ma { background-image: url('img/flags/ma.png'); }
	&.mc, &.country_mc { background-image: url('img/flags/mc.png'); }
	&.md, &.country_md { background-image: url('img/flags/md.png'); }
	&.mg, &.country_mg { background-image: url('img/flags/mg.png'); }
	&.mh, &.country_mh { background-image: url('img/flags/mh.png'); }
	&.mk, &.country_mk { background-image: url('img/flags/mk.png'); }
	&.ml, &.country_ml { background-image: url('img/flags/ml.png'); }
	&.mm, &.country_mm { background-image: url('img/flags/mm.png'); }
	&.MNE, &.country_MNE { background-image: url('img/flags/mne.png'); }
	&.mn, &.country_mn { background-image: url('img/flags/mn.png'); }
	&.mo, &.country_mo { background-image: url('img/flags/mo.png'); }
	&.mq, &.country_mq { background-image: url('img/flags/mq.png'); }
	&.mp, &.country_mp { background-image: url('img/flags/mp.png'); }
	&.mr, &.country_mr { background-image: url('img/flags/mr.png'); }
	&.ms, &.country_ms { background-image: url('img/flags/ms.png'); }
	&.mt, &.country_mt { background-image: url('img/flags/mt.png'); }
	&.mu, &.country_mu { background-image: url('img/flags/mu.png'); }
	&.mv, &.country_mv { background-image: url('img/flags/mv.png'); }
	&.mw, &.country_mw { background-image: url('img/flags/mw.png'); }
	&.mx, &.country_mx { background-image: url('img/flags/mx.png'); }
	&.my, &.country_my { background-image: url('img/flags/my.png'); }
	&.mz, &.country_mz { background-image: url('img/flags/mz.png'); }
	&.na, &.country_na { background-image: url('img/flags/na.png'); }
	&.nc, &.country_nc { background-image: url('img/flags/nc.png'); }
	&.ne, &.country_ne { background-image: url('img/flags/ne.png'); }
	&.nf, &.country_nf { background-image: url('img/flags/nf.png'); }
	&.ng, &.country_ng { background-image: url('img/flags/ng.png'); }
	&.ni, &.country_ni { background-image: url('img/flags/ni.png'); }
	&.nl, &.country_nl { background-image: url('img/flags/nl.png'); }
	&.no, &.country_no { background-image: url('img/flags/no.png'); }
	&.np, &.country_np { background-image: url('img/flags/np.png'); }
	&.nr, &.country_nr { background-image: url('img/flags/nr.png'); }
	&.nu, &.country_nu { background-image: url('img/flags/nu.png'); }
	&.nz, &.country_nz { background-image: url('img/flags/nz.png'); }
	&.om, &.country_om { background-image: url('img/flags/om.png'); }
	&.pa, &.country_pa { background-image: url('img/flags/pa.png'); }
	&.pe, &.country_pe { background-image: url('img/flags/pe.png'); }
	&.pf, &.country_pf { background-image: url('img/flags/pf.png'); }
	&.pg, &.country_pg { background-image: url('img/flags/pg.png'); }
	&.ph, &.country_ph { background-image: url('img/flags/ph.png'); }
	&.pk, &.country_pk { background-image: url('img/flags/pk.png'); }
	&.pl, &.country_pl { background-image: url('img/flags/pl.png'); }
	&.pm, &.country_pm { background-image: url('img/flags/pm.png'); }
	&.pn, &.country_pn { background-image: url('img/flags/pn.png'); }
	&.pr, &.country_pr { background-image: url('img/flags/pr.png'); }
	&.ps, &.country_ps { background-image: url('img/flags/ps.png'); }
	&.pt, &.country_pt, &.pt-pt, &.country_pt-pt { background-image: url('img/flags/pt.png'); }
	&.pw, &.country_pw { background-image: url('img/flags/pw.png'); }
	&.py, &.country_py { background-image: url('img/flags/py.png'); }
	&.qa, &.country_qa { background-image: url('img/flags/qa.png'); }
	&.re, &.country_re { background-image: url('img/flags/re.png'); }
	&.ro, &.country_ro { background-image: url('img/flags/ro.png'); }
	&.rs, &.country_rs { background-image: url('img/flags/rs.png'); }
	&.ru, &.country_ru { background-image: url('img/flags/ru.png'); }
	&.rw, &.country_rw { background-image: url('img/flags/rw.png'); }
	&.sa, &.country_sa { background-image: url('img/flags/sa.png'); }
	&.sb, &.country_sb { background-image: url('img/flags/sb.png'); }
	&.sc, &.country_sc { background-image: url('img/flags/sc.png'); }
	&.sd, &.country_sd { background-image: url('img/flags/sd.png'); }
	&.SSD, &.country_SSD { background-image: url('img/flags/ssd.png'); }
	&.sg, &.country_sg { background-image: url('img/flags/sg.png'); }
	&.sh, &.country_sh { background-image: url('img/flags/sh.png'); }
	&.si, &.country_si { background-image: url('img/flags/si.png'); }
	&.sj, &.country_sj { background-image: url('img/flags/sj.png'); }
	&.sk, &.country_sk { background-image: url('img/flags/sk.png'); }
	&.sl, &.country_sl { background-image: url('img/flags/sl.png'); }
	&.sm, &.country_sm { background-image: url('img/flags/sm.png'); }
	&.sn, &.country_sn { background-image: url('img/flags/sn.png'); }
	&.so, &.country_so { background-image: url('img/flags/so.png'); }
	&.SRB, &.country_SRB { background-image: url('img/flags/srb.png'); }
	&.sr, &.country_sr { background-image: url('img/flags/sr.png'); }
	&.st, &.country_st { background-image: url('img/flags/st.png'); }
	/*&.sv,*/ &.country_sv { background-image: url('img/flags/sv.png'); }
	&.sy, &.country_sy { background-image: url('img/flags/sy.png'); }
	&.sz, &.country_sz { background-image: url('img/flags/sz.png'); }
	&.tc, &.country_tc { background-image: url('img/flags/tc.png'); }
	&.td, &.country_td { background-image: url('img/flags/td.png'); }
	&.tf, &.country_tf { background-image: url('img/flags/tf.png'); }
	&.tg, &.country_tg { background-image: url('img/flags/tg.png'); }
	&.th, &.country_th { background-image: url('img/flags/th.png'); }
	&.tj, &.country_tj { background-image: url('img/flags/tj.png'); }
	&.tk, &.country_tk { background-image: url('img/flags/tk.png'); }
	&.tl, &.country_tl { background-image: url('img/flags/tl.png'); }
	&.tm, &.country_tm { background-image: url('img/flags/tm.png'); }
	&.tn, &.country_tn { background-image: url('img/flags/tn.png'); }
	&.to, &.country_to { background-image: url('img/flags/to.png'); }
	&.tr, &.country_tr { background-image: url('img/flags/tr.png'); }
	&.tt, &.country_tt { background-image: url('img/flags/tt.png'); }
	&.tv, &.country_tv { background-image: url('img/flags/tv.png'); }
	&.tw, &.country_tw { background-image: url('img/flags/tw.png'); }
	&.tz, &.country_tz { background-image: url('img/flags/tz.png'); }
	&.ua, &.country_ua { background-image: url('img/flags/ua.png'); }
	&.ug, &.country_ug { background-image: url('img/flags/ug.png'); }
	&.uy, &.country_uy { background-image: url('img/flags/uy.png'); }
	&.uz, &.country_uz { background-image: url('img/flags/uz.png'); }
	&.va, &.country_va { background-image: url('img/flags/va.png'); }
	&.vc, &.country_vc { background-image: url('img/flags/vc.png'); }
	&.ve, &.country_ve { background-image: url('img/flags/ve.png'); }
	&.vg, &.country_vg { background-image: url('img/flags/vg.png'); }
	&.vi, &.country_vi { background-image: url('img/flags/vi.png'); }
	&.vn, &.country_vn { background-image: url('img/flags/vn.png'); }
	&.vu, &.country_vu { background-image: url('img/flags/vu.png'); }
	&.wf, &.country_wf { background-image: url('img/flags/wf.png'); }
	&.ws, &.country_ws { background-image: url('img/flags/ws.png'); }
	&.ye, &.country_ye { background-image: url('img/flags/ye.png'); }
	&.yt, &.country_yt { background-image: url('img/flags/yt.png'); }
	&.za, &.country_za { background-image: url('img/flags/za.png'); }
	&.zm, &.country_zm { background-image: url('img/flags/zm.png'); }
	&.zw, &.country_zw { background-image: url('img/flags/zw.png'); }
}