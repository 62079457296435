.sub-page-menu {
	@include fill-parent;

	border-bottom: 1px solid transparent;

	.light-top & {

	}
	.dark-top & {
		background-color: #fff;
		border-bottom: 1px solid rgba(0, 0, 0, 0.08);
	}
	.dark-top:not(.page-template-blog) & {
		margin: 0 rem(-32);
		width: calc(100% + #{rem(64)});
		position: relative;

		@include media($mobile) {
			margin: 0 rem(-24);
			width: calc(100% + #{rem(48)});
		}

	}
	
	text-align: center;

	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;

	ul li {
		display: inline-block;
		line-height: rem(64);

		a {
			display: inline-block;
			color: $dark-font-color;
			text-transform: uppercase;
			font-size: rem(12);
			letter-spacing: 3px;
			font-family: $demi-font;

			border-top: 3px solid transparent;
			border-bottom: 3px solid transparent;

			.light-top &,
			.dark-top & {
				border-top: 3px solid transparent;
				border-bottom: 3px solid transparent;
			}

			padding: 0px rem(12);

			@include media($mobile) {
				letter-spacing: 1px;
				padding: 0 rem(8);
			}

			&.active {
				color: $red;

				border-bottom: 3px solid $red;
			}
		}
	}
}