.footer.wrapper {
	padding-top: rem(32);
	padding-bottom: rem(32);

	@include media($mobile) {
		padding-top: rem(48);
		padding-bottom: rem(48);
	}

	div.logo {
		@include span-columns(1);

		a {
			display: inline-block;
			width: 52px;
			height: 66px;

			background-image: url('img/vertical-logo.svg');
			background-size: 52px 66px;
		}

		&:first-child {
			@include media($mobile) {
				display: none;
			}
		}

		&:last-child {
			display: none;

			@include media($mobile) {
				display: block;
				@include span-columns(12);
				text-align: center;
			}
		}
	}

	ul.menu {
		@include span-columns(9.7);
		margin-bottom: rem(64);
		font-size: 15px;
		display: flex;

		li {
			display: inline-block;
			margin-right: rem(50);
			position: relative;
			font-weight: 100;

			&:first-child {
				margin-left: 0;
				font-weight: bold;
			}

			a {
				color: rgb(137, 144, 154);
			}
		}

		@include media($mobile) {
			@include span-columns(12);

			margin-bottom: rem(64);

			li {
				@include fill-parent();
				text-align: center;

				a {
					width: 100%;
				}
				&:first-child {
					margin-top: 0;
				}
			}
		}

		@include media($tablet-portrait) {
			display: flex !important;
			@include span-columns(12);
			li {
				margin: 0 rem(8);

				&:first-child {
					margin-left: rem(8);
				}
			}
		}
		ul.submenu-footer {
			li {
				display: block;
				padding: rem(5) 0;
				font-weight: 500;
				color: rgb(137, 144, 154);
				&:first-child {
					font-weight: 600;
					color: $dark-bg-color;
					padding-bottom: rem(12);
					@include media($mobile) {
						padding-top: rem(25);
						padding-bottom: rem(10);
					}
				}

			}


		}
	}

	div.language-footer {
		@include span-columns(2);

		@include media($mobile){
			display: none;
			//@include span-columns(4);
		}
		@include media($tablet-portrait) {
			@include span-columns(4);
				margin: 0 rem(15) rem(35);
		}
	}

	div.info-footer {
		@include span-columns(6);
		display: flex;
		margin-bottom: rem(40);
		font-size: 12px;
		line-height: 19px;
		height: 32px;

		@include media($mobile1100) {
			@include span-columns(12);
			display: flex;
		}

		@include media($tablet-portrait) {
			margin: 0 rem(15);
		}

		.info-footer-logo {
			img {
				height: 32px;
			}
		}

		.info-footer-text {
			margin-left: rem(10);
			align-self: center;
		}

	}
}
