.layout-part.layout-type-01 {

	.container {
		padding: rem(224) 0;

		@include media($mobile) {
			padding: rem(88) 0;
		}
	}

	/*
	.background-image-clipper {
		@include shift-pos-right(7 of 12);

	    position: absolute;
	    clip: rect(auto,auto,auto,auto);
	    width: 100%;
	    height: 100%;
	}
	*/

	.background-image {
		@include shift-pos-right(7 of 12);

		//background-attachment: fixed;
		//position: fixed;

		.touch & {
			background-attachment: scroll;
		}
		
		@include media($mobile) {
			display: none;
			/*
			position: relative;
			height: 480px;
			left: 0;
			z-index: 1;
			*/
		}
	}

	.content-text {
		@include span-columns(5);

		@include media($mobile) {
			@include span-columns(12);
		}
	}

}