.blog {
	.top.wrapper {
		background-image: url('img/page-headers/blog.png');
	}

	.small-thick-hr {
		display: block;
		margin-bottom: rem(16);
	}

	.featured.wrapper {
		border-bottom: 1px solid $hr-color;
	}

	.featured.container {
		padding: rem(84) 0;

		@include media($mobile) {
			padding: rem(48) 0;
		}

		> h4 {
			@include span-columns(10);
			@include shift(1);

			@include media($mobile) {
				@include span-columns(12);
				@include shift(0);
			}

			text-transform: uppercase;
			color: $dark-font-color;
			//font-family: $regular-font;
			margin-bottom: rem(32);
		}

		.item {
			@include span-columns(10);
			@include shift(1);

			padding-bottom: rem(48);

			@include media($mobile) {
				@include span-columns(12);
				@include shift(0);
				padding-bottom: rem(32);
			}

			a.thumbnail {
				@include span-columns(6 of 10);

				@include media($mobile) {
					@include span-columns(12);
					@include shift(0);
					margin-bottom: rem(16);
				}

				img {
					height: auto;
					width: 100%;
				}
			}

			.content {
				@include span-columns(4 of 10);

				@include media($mobile) {
					@include span-columns(12);
					@include shift(0);
				}

				h2 a {
					color: $dark-font-color;
				}

				.excerpt a {
					color: $medium-font-color;
				}
			}
		}
	}

	.posts.container {
		padding: rem(84) 0 rem(32) 0;

		@include media($mobile) {
			padding-top: rem(48);
		}

		.item {
			@include span-columns(6);
			@include shift(3);

			@include media($mobile) {
				@include span-columns(12);
				@include shift(0);
			}

			padding: rem(48) 0;

			border-bottom: 1px solid $hr-color;

			&:last-child {
				border-bottom: none;
			}

			img {
				width: 100%;
				height: auto;
				margin-bottom: rem(32);
			}

			.time {
				color: $light-font-color;
			}		

			h2 a {
				color: $dark-font-color;
			}

			.excerpt a {
				color: $medium-font-color;
			}	
		}
	}

	.more-posts.container {
		padding-bottom: rem(128);

		@include media($mobile) {
			padding-bottom: rem(64);
		}

		.content {
			@include span-columns(6);
			@include shift(3);
			text-align: opposite-layout-direction();

			@include media($mobile) {
				@include span-columns(12);
				@include shift(0);

				text-align: center;
			}
		}
	}
}