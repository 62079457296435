@mixin border-image($images) {
  -webkit-border-image: _border-add-prefix($images, webkit);
     -moz-border-image: _border-add-prefix($images, moz);
       -o-border-image: _border-add-prefix($images, o);
          border-image: _border-add-prefix($images);
}

@function _border-add-prefix($images, $vendor: false) {
  $border-image: null;
  $images-type:  type-of(nth($images, 1));
  $first-var:    nth(nth($images, 1), 1);          // Get type of Gradient (Linear || radial)

  // If input is a gradient
  @if $images-type == string {
    @if ($first-var == "linear") or ($first-var == "radial") {
      $gradient-type: nth($images, 1);           // Get type of gradient (linear || radial)
      $gradient-pos:  nth($images, 2);           // Get gradient position
      $gradient-args: nth($images, 3);           // Get actual gradient (red, blue)
      $gradient-positions: _gradient-positions-parser($gradient-type, $gradient-pos);
      $border-image:  _render-gradients($gradient-positions, $gradient-args, $gradient-type, $vendor);
    }
    // If input is a URL
    @else {
      $border-image: $images;
    }
  }
  // If input is gradient or url + additional args
  @else if $images-type == list {
    $type: type-of(nth($images, 1));           // Get type of variable - List or String

    // If variable is a list - Gradient
    @if $type == list {
      $gradient: nth($images, 1);
      $gradient-type: nth($gradient, 1);           // Get type of gradient (linear || radial)
      $gradient-pos:  nth($gradient, 2);           // Get gradient position
      $gradient-args: nth($gradient, 3);           // Get actual gradient (red, blue)
      $gradient-positions: _gradient-positions-parser($gradient-type, $gradient-pos);
      $border-image:  _render-gradients($gradient-positions, $gradient-args, $gradient-type, $vendor);

      @for $i from 2 through length($images) {
        $border-image: append($border-image, nth($images, $i));
      }
    }
  }
  @return $border-image;
}

//Examples:
// @include border-image(url("image.png"));
// @include border-image(url("image.png") 20 stretch);
// @include border-image(linear-gradient(45deg, orange, yellow));
// @include border-image(linear-gradient(45deg, orange, yellow) stretch);
// @include border-image(linear-gradient(45deg, orange, yellow) 20 30 40 50 stretch round);
// @include border-image(radial-gradient(top, cover, orange, yellow, orange));

