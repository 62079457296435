.prices-numbers {

	.subheader {
		color: $medium-font-color;
	}

	.content.wrapper {
		padding-top: rem(128);
		padding-bottom: rem(128);

		.content {
			//position: relative;
		}

		@include media($mobile768) {
			padding-top: rem(32);
			padding-bottom: rem(32);
		}
	}

	.form {
		@include span-columns(5);
		@include shift(1);

		@include media($mobile768) {
			@include span-columns(12);
			@include shift(0);
		}

		@include media($small-screen) {
			@include shift(0.5);
		}

		.form-section {
			margin-bottom: rem(72);
		}

	  	//.restrictionsInfo{
		//  	text-align: left;
		//}

		.restrictions {
			color: $dark-font-color;
			background-color: $light-bg-color;
			border: 1px solid rgba(215, 215, 215, 0.65);
			font-size: rem(14);
			padding: 0 rem(16);

			ul {
				list-style-type: disc;

				.rtl & {
					list-style-position: inside;
				}
			}

			li {
/*				margin: rem(16) 0;
				margin-left: rem(16);*/
				list-style: none;
				margin: rem(16) 0;
				margin-left: rem(16);


				br {
					float: right;
				}
			}

		    li:before {
				//content: "·";
				//font-size: 46px;
				//vertical-align: bottom;
				//line-height: 20px;
				//margin-right: 0.6rem;
				//margin-left: 0.1rem;
				//color: #292d33;
			}
		}

		.plan-buttons .description,
		.form .restrictionInfo {
			a {
				font-size: rem(14);
				text-decoration: underline;
				color: $medium-font-color;
			}
		}

		.plan-buttons {
			margin-bottom: rem(8);
			font-size: 0; // prevent line break between divs
			height: rem(125);
			position: relative;
			overflow: hidden;
			color: $dark-font-color;

			@include transition(all 0.2s ease-in-out);

			.description {
				//display: none;
				padding-top: rem(16);
				opacity: 0;
				visibility: hidden;
				@include transition(all 0.2s ease-in-out);

			}

			&.show-descriptions {
				height: rem(330);

				.description {
					//display: inline;
					opacity: 1;
					visibility: visible;
				}
			}

			> div {
				//height: 100%;
				font-size: rem(16);
				border: 1px solid $hr-color;
				width: 50%;
				text-align: center;
				padding: rem(32) 0;
				cursor: pointer;
				position: absolute;
				//height: 100%;
				top: 4px;
				bottom: 4px;

				@include transition(all 0.1s ease-in-out);

				h4 {
					margin-bottom: 0;
				}

				&.right {
					left: 50%;
					margin-left: -1px;
				}

				&.selected {
					background-color: $light-bg-color;
					top: 0;
					bottom: 0;
					padding: rem(36) 0;
				}
			}
		}

		.slider {
			margin-top: rem(48);
			margin-bottom: rem(36);
		}

		.hours-chosen {
			text-align: right;
			margin-bottom: rem(8);
		}

		.hours-chosen-text {
			text-align: right;
		}

		h4 {
			margin-bottom: rem(24);
		}
		.info-link-wrap {
			position: relative;
		}
		.info-link {
			font-size: rem(12);
			color: $light-font-color;
			//border-bottom: 1px solid $light-font-color;
			text-decoration: underline;
			cursor: pointer;
			margin-top: rem(8);
			display: block;
		}

	}

	.summary {
		position: relative;
		@include span-columns(4);
		@include shift(1);

		@include media($tablet-portrait) {
			position: inherit;
			width: 100%;
			margin: 0;
		}

		@include media($small-screen) {
			@include span-columns(6);
			@include shift(0.5);
		}

		@include media($mobile) {
			@include span-columns(12);
			@include shift(0);

			position: fixed;
			bottom: -100%;
			left: 0;
			z-index: 10;
			background: #f8f9fC;
			padding-right: 0;
			&.mobile-price-info-open {
				bottom: 0;
			}
		}

		.inner {
			position: relative;

			background-color: $light-bg-color;
			padding: rem(24) rem(48);


			h3 {
				color: $dark-font-color;
				text-align: center;
				font-family: $regular-font;
				font-weight: normal;
			}

			table {
				width: 100%;
				font-size: 14px;
			}

			tr.one_time_fee {
				border-bottom: 1px solid $hr-color;
				padding-bottom: 0.5rem;
			}
			//tr:first-child {
			//	th,td {
			//		border-bottom: 1px solid $hr-color;
			//		padding-bottom: rem(8);
			//	}
			//}

			//tr:nth-child(2) {
			//	th, td {
			//		padding-top: rem(8);
			//	}
			//}

			th, td {
				padding: rem(4) 0;
			}

			th {
				font-weight: normal;
				color: $dark-font-color;
				text-align: left;
			}

			td {
				color: $dark-font-color;
				text-align: right;

				&.placeholder {
					font-size: rem(12);
					font-style: italic;
				}
			}

			.sum {
				font-size: rem(32);
				margin-top: rem(48);
				color: $dark-font-color;
			}

			.smalltext {
				font-size: rem(11);

			}

			.cta-button {
				width: 100%;
				min-width: inherit;
			}
		}

		.small-links {
			font-size: rem(12);
			padding-top: rem(8);

			.help-link {
				float: right;
				color: $medium-font-color;
				text-decoration: underline;
			}
		}
	}
	/*
	.summary-mobile {
		background-color: $light-bg-color;

		width: 100%;
		position: fixed;
		height: 80px;
		bottom: 0;

		.top {
			position: absolute;
			width: 100%;
			height: 80px;

			.button {
				background-color: $red;
				color: #fff;
				position: absolute;
				top: 0;
				right: 0;
				width: 130px;
				height: 80px;
			}
		}
	}
	*/

	@include media($mobile768) {
		.summary-box > .inner {
			border: solid rgba(215, 215, 215, 0.65);
			border-width: 1px 0 0 0;

			.sum,
			.smalltext {
				text-align: right;
			}

			.cta-button {
				-webkit-border-radius: 0;
				-moz-border-radius: 0;
				-o-border-radius: 0;
				border-radius: 0;
			}

		}
		.summary-box > .inner,
		.summary > .small-links {
			/* display: none; */
			opacity: 0;
		}

		.summary-box.mobile-price-info-open {
			> .inner,
			> .small-links {
				/* display:block; */
				opacity: 1;
			}
		}

		.summary {
			.inner {
				padding: rem(16);
			}
			.small-links {
				background: #f8f9fC;
				padding: 0 3rem 1.5rem 3rem;
			}
		}
	}
}

.rtl.prices-numbers .summary .small-links .help-link {
	float: left;
}

@include media($mobile768) {
	.mobile-menu-open .summary-box {
		display: none;
	}
}

.view-full-list {
	display: none !important;
	.prices-numbers .form & {
		display: block !important;
		text-decoration: none;
		color: #f24a4a;
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;
	}
	.rtl.prices-numbers .form & {
		right: auto;
		left: 0;
	}
}


