.partners {
	.top.wrapper {
		background-image: url('img/page-headers/partners.png');
	}

	.info.wrapper {
		background-color: $light-bg-color;
		padding-top: rem(128);
		padding-bottom: rem(128);

		.content {
			@include span-columns(10);
			@include shift(1);

			.paragraphs {
				margin-top: rem(56);
			}

			.email {
				@include span-columns(10 of 10);
			}

			.paragraph {
				@include span-columns(5 of 10);
				@include omega(2n);

				margin-bottom: rem(48);
			}
		}

		@include media($mobile) {
			text-align: center;
			padding-top: rem(64);
			padding-bottom: rem(64);

			.content {
				@include span-columns(12);
				@include shift(0);

				.paragraph {
					@include span-columns(12);

					margin-bottom: rem(32);
				}
			}
		}
	}
}