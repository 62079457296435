.contact {
	.top.wrapper {
		background-color: $light-bg-color;

		.subheader {
			//font-size: rem(24);
			display: inline-block;
			color: $light-font-color;
			margin-bottom: rem(56);

			@include media($mobile) {
				margin-bottom: rem(32);

				@include span-columns(9);
				@include shift(1.5);
			}
		}

		.phone {
			font-size: rem(32);
			line-height: rem(32);
			margin-bottom: rem(32);

			a {
				color: $dark-font-color;
			}

			.flag {
				display: inline-block;
				width: rem(32);
				height: rem(32);

				vertical-align: center;
				margin-bottom: -5px;

				background-size: contain;
				background-repeat:no-repeat;
				background-position: center;

				margin-right: rem(16);
			}
		}
	}

	.contact-form.wrapper {
		padding-top: rem(128);
		padding-bottom: rem(208);

		@include media($mobile) {
			padding-top: rem(64);
			padding-bottom: rem(64);

			text-align: center;
		}

		.content {
			@include span-columns(10);
			@include shift(1);

			@include media($mobile) {
				@include span-columns(12);
				@include shift(0);
			}

			@include media($tablet-portrait) {
				@include span-columns(12);
				@include shift(0);
			}

			@include media($small-screen) {
				@include span-columns(12);
				@include shift(0);
			}

			.contact {
				@include span-columns(2 of 10);

				margin-top: rem(64);
				line-height: rem(24);

				a {
					display: block;
					margin-bottom: rem(16);
				}

				@include media($mobile) {
					margin-top: 0;
					margin-bottom: rem(32);

					@include span-columns(12);
				}

				@include media($tablet-portrait) {
					//@include span-columns(5 of 12);
				}

				@include media($small-screen) {
					//@include span-columns(5 of 12);
				}
			}

			.form {
				@include span-columns(5 of 10);
				@include shift(0.6);

				@include media($mobile) {
					@include span-columns(12);
					@include shift(0);
				}

				@include media($tablet-portrait) {
					//@include span-columns(7 of 12);
					//@include shift(0);
				}

				@include media($small-screen) {
					//@include span-columns(7 of 12);
					//@include shift(0);
				}

				.input {
					position: relative;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;

					input {
						background-repeat: no-repeat;
						background-position: rem(24) center;
						line-height: rem(66);
						padding: rem(4) rem(24) rem(0) rem(64);

						@include media($mobile) {
							background-position: rem(16) center;
							line-height: rem(30);
							padding: rem(12) rem(10) rem(8) rem(56);
						}
					}

					.email {
						background-image: url('img/icons/message.svg');
					}

					.name {
						background-image: url('img/icons/user.svg');
					}

					textarea {
						height: rem(234);
						padding: rem(24);

						@include media($mobile) {
							height: rem(128);
						}
					}

					input, textarea {
						width: 100%;
						border: 1px solid #dcdcdc;
						margin-bottom: rem(16);

						&.invalid {
							border: 1px solid $red;
						}
					}
				}

				button {
					border-radius: 0 !important;
				}

			}
			.thank-you-message {
				font-size: rem(18);
				margin-top: rem(16);
			}

			.error-message {
				font-size: rem(18);
				margin-top: rem(16);
				color: $red;
			}
		}
	}
}

.rtl.contact .top.wrapper .phone a {
	color: #292d33;
	unicode-bidi: bidi-override !important;
}


/* custom styles for Contact Form 7 plugin */
.wpcf7 p {
	margin: 0;
}
.wpcf7 form .wpcf7-response-output {
    border: 0px;
}
.wpcf7 form.sent .wpcf7-response-output {
    border: 0px;
}
.wpcf7 form.failed .wpcf7-response-output,
.wpcf7 form.aborted .wpcf7-response-output,
.wpcf7 form.spam .wpcf7-response-output,
.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output {
    border: 0px;
    color: $red;
}