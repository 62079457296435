.layout-part.layout-header-cta {

	.container {
		padding: rem(160) 0;

		@include media($mobile) {
			padding: rem(72) 0;
		}
	}

}