@mixin shift-pos-right($n-columns: 1) {
	@include shift-pos-in-context($n-columns, right);
}

@mixin shift-pos-left($n-columns: 1) {
	@include shift-pos-in-context($n-columns, left);
}

@mixin shift-pos-in-context($shift: $columns of $container-columns, $direction: left) {
	$n-columns: nth($shift, 1);
	$parent-columns: container-shift($shift);

	@if($default-layout-direction == RTL) {
		$direction: get-opposite-direction($direction);
	}

	//$direction: get-direction($layout-direction, $default-layout-direction);
	$opposite-direction: get-opposite-direction($direction);

	#{$opposite-direction}: $n-columns * flex-grid(1, $parent-columns) + $n-columns * flex-gutter($parent-columns);

	// Reset nesting context
	$parent-columns: $grid-columns;
}

@function layout-direction() {
	@if($default-layout-direction == LTR) {
		@return left;
	} @else {
		@return right;
	}
}

@function opposite-layout-direction() {

	@if($default-layout-direction == LTR) {
		@return get-opposite-direction(left);
	} @else {
		@return get-opposite-direction(right);
	}
}

@function rem($pxval, $base: 16) {
  @return ($pxval / $base) * 1rem;
}