.layout-part.layout-customer-testimonials {

	border-top: 1px solid rgba(0, 0, 0, 0.08);
	border-bottom: 1px solid rgba(0, 0, 0, 0.08);

	@include media($mobile) {
		border-bottom: 1px solid $hr-color;
	}

	.container {
		text-align: center;
		height: rem(288);
		box-sizing: content-box;
		padding-bottom: rem(112);
		overflow-x: hidden;
		padding-top: rem(224);	
		
		@include media($mobile) {
			padding-top: rem(96);
			padding-bottom: rem(96);	
		}

		.slide {
			position: absolute;

			@include span-columns(10);
			@include shift(1);

			@include transition(all 0.5s ease-in-out 0.5s);

			&.hidden {
				margin-top: 10px;
				opacity: 0;
				visibility: hidden;
				@include transition(all 0.5s ease-in-out 0s);
			}

			.photo {
				display: inline-block;
				width: rem(100);
				height: rem(100);
				border-radius: rem(50);

				background-position: center;
				background-size: cover;

				margin-bottom: rem(8);
			}

			.name {
				color: $dark-font-color;
				font-size: rem(18);
			}

			.title {
				color: $medium-font-color;
				font-size: 1rem;	
			}

			.quote {
				color: $light-font-color;
				font-size: rem(28);
				margin-top: rem(24);
				line-height: rem(28);

				@include media($mobile) {
					font-size: rem(18);
					line-height: rem(18);
				}
			}
		}

		.dots {
			position: absolute;
			bottom: rem(112);
			@include span-columns(10);
			@include shift(1);

			@include media($mobile) {
				bottom: rem(32);
			}

			.dot {
				width: rem(8);
				height: rem(8);
				border-radius: rem(4);
				background-color: $light-font-color;
				opacity: 0.35;
				display: inline-block;
				margin: 0 rem(6);

				@include transition(all 0.5s ease-in-out 0.2s);

				&.active {
					opacity: 1;
					@include transition(all 0.5s ease-in-out 0s);
				}
			}
		}
	}
}