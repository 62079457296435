.layout-part.layout-video-embed {

	.container {
		padding-top: 8rem;
		padding-bottom: 8rem;
		iframe {
			width: 100%;
			display: inline-block;
		}
	}

}
