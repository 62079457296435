.dark-top {
	.top.wrapper {
		background-color: #000;
	}

	&:not(.page-template-blog) {
		.top.wrapper {
			display: flex;
			flex-flow: column nowrap;
			align-items: center;
			justify-content: flex-start;

			.container {
				margin: 0;
				width: 100%;
			}

			.hero.container {
				flex-grow: 1;
				display: flex;
				align-items: center;
				margin-left: 0;
				margin-bottom: 1rem;
				width: 100%;
				padding-top: 0;
			}
		}
	}
}

.top.wrapper {
	position: relative;
	color: $hero-content-color;
	color: #fff;
	background: #F8F9FC;
	background-size: cover;
	background-position: center;

	.help &,
	.single &,
	.job-opening &,
	.investor & {
		background-color: #fff;
	}

	.big-top & {
		min-height: 770px;
		@include media($mobile) { min-height: 480px };
	}

	.medium-top & {
		min-height: 576px;
		@include media($mobile) { min-height: 400px }
	}

	.small-top & {
		min-height: 384px;
		@include media($mobile) { min-height: 256px }
	}

	.light-top & {
		color: $dark-font-color;
	}

	.gray-top & {
		color: $dark-font-color;
		background-color: $light-bg-color;
	}

	.hero.container {
		position: relative;
		padding-top: rem(224 - 60);

		@include media($mobile) {
			padding-top: rem(104);
		}
	}

	.content {
		@include span-columns(12);

		text-align: center;

		.subheader {
			font-size: rem(24);
			line-height: rem(32);

			@include media($mobile) {
				font-size: rem(16);
				line-height: rem(24);
			}
		}

		.cta-container {
			display: block;
			padding-top: rem(48);

			@include media($mobile) {
				padding-top: rem(16);
			}
		}
	}
}