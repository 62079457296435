.help {
	h1 {
		color: $dark-font-color;
	}

	.search.container {
		padding-top: rem(128);
		padding-bottom: rem(128);
		text-align: center;

		@include media($mobile) {
			padding-top: rem(64);
			padding-bottom: rem(64);
		}

		.subheader {
			@include span-columns(8);
			@include shift(2);

			min-height: rem(56);
			font-size: rem(24);
			line-height: rem(24);

			@include media($mobile) {
				font-size: rem(16);

				@include span-columns(12);
				@include shift(0);

				margin-bottom: rem(16);
			}
		}

		.search-box {
			@include span-columns(8);
			@include shift(2);

			@include media($mobile) {
				@include span-columns(12);
				@include shift(0);
			}
		}

		.contact-info {
			@include span-columns(8);
			@include shift(2);

			@include media($mobile) {
				@include span-columns(12);
				@include shift(0);
			}

			padding-top: rem(12);

			.email-us {
				float: left;
			}

			.call-us {
				float: right;
				unicode-bidi: bidi-override !important;

				a {
					color: $dark-font-color;
				}
			}
		}
	}

	&.support-center {
		.subsection-ctas {
			.bg-left {
				//right: 50%;
				width: 50.1%;
				
				@include media($mobile) {
					display: none;
				}
			}

			.bg-right {
				//left: 50%;
				width: 50.1%;
				left: auto;
				right: 0;
				
				@include media($mobile) {
					display: none;
				}
			}

			.container {
				padding: 0;
				height: rem(470);

				@include media($mobile) {
					height: auto;
				}

				> div {
					position: relative;
					//padding: rem(96) 0;
					height: 100%;
					text-align: center;

					.contents {
						padding-top: rem(96);

						@include span-columns(3 of 5);
						@include shift(2.5);

						@include media($mobile) {
							@include span-columns(12);
							@include shift(0);

							position: relative;
							padding-top: 0;
							height: rem(240);
						}
					}

					@include media($mobile) {
						padding: rem(64) 0 rem(32) 0;
					}

					h2 {
						color: #fff;
					}

					h4 {
						line-height: rem(24);
						color: #fff;
					}

					.background-image {
						display: none;
						position: absolute;
						left: - rem(16);
						right: - rem(16);
						height: 101%;

						@include media($mobile) {
							display: block;
						}
					}
				}

				.left {
					@include span-columns(5);

					@include media($mobile) {
						@include span-columns(12);
						//margin-bottom: rem(32);
					}
				}

				.right {
					@include span-columns(5);
					@include shift(2);

					@include media($mobile) {
						@include span-columns(12);
						@include shift(0);
					}
				}

				a {
					position: absolute;
					bottom: rem(96);
					left: 50%;
					//margin-left: - rem(90);
					//width: rem(180);

					transform: translateX(-50%);

					@include media($mobile) {
						bottom: rem(32);
					}
				}
			}
		}
	}

	.topics.container .topic {
		@include span-columns(4);
		@include omega(3n);

		background-color: $light-bg-color;

		&:hover {
			background-color: $light-bg-color-hover;
		}

		color: $medium-font-color;

		margin-bottom: rem(24);
		padding: rem(32);

		height: rem(172);

		@include media($mobile) {
			@include span-columns(12);
		}
	}

	&.help-topic {
		.content.wrapper .container {
			padding-top: rem(128);
			padding-bottom: rem(128);

			@include media($mobile) {
				padding-bottom: 0;
			}
			
			.top {
				@include span-columns(10);
				@include shift(1);
				margin-bottom: rem(96);

				text-align: center;

				.subheader {
					font-size: rem(24);
					line-height: rem(24);

					@include media($mobile) {
						font-size: rem(16);
					}
				}
			}
			
			.content {
				@include span-columns(10);
				@include shift(1);

				.subtopic {
					@include span-columns(5 of 10);
					@include omega(2n);

					margin-bottom: rem(32);

					&:hover {
						background-color: $light-bg-color;

						-webkit-box-shadow: -4px 0px 0px 8px $light-bg-color;
						-moz-box-shadow: -4px 0px 0px 8px $light-bg-color;
						box-shadow: -4px 0px 0px 8px $light-bg-color;
					}

					/*
					position: relative;
					z-index: 2;

					
					&:after {
						content: '';
						position: absolute;
						top: 0;
						background-color: #ccc;
						display: block;
						width: 100%;
						height: 100%;
						z-index: 1;
					}

					&:hover:after {
						background-color: #ccc;
					}
					*/

					h4 {
						color: $dark-font-color;
					}

					p {
						color: $medium-font-color;
					}

					@include media($mobile) {
						@include span-columns(12);
					}
				}
			}
		}
	}
}