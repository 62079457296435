.price-panel-mob {
  display: none;
  position: fixed;
  top: 80px;
  left: 0;
  border: solid rgba(215, 215, 215, 0.65);
  border-width: 1px 0 1px 0;
  background: #f8f9fC;
  z-index: 10;
  width: 100%;
  padding: 10px 70px 5px 15px;
  text-align: right;
}

.price-panel-mob .logo {
  visibility: hidden;
}

.price-panel-mob .logo a:first-child {
  width: 7.6875rem;
  height: 3rem;
  display: block;
  float: left;
  background-size: 7.6875rem 2.25rem;
  background: url("img/header/logo-dark.svg") left center no-repeat;
}

.price-panel-mob .sum {
  font-family: 'Montserrat Regular';
  font-size: 2rem;
  line-height: 2rem;
  color: #292d33;
  cursor: pointer;
}

.price-panel-mob .smalltext {
  font-size: 0.6875rem;
  color: #7e7e94;
}

.price-panel-mob .icon {
  position: absolute;
  top: 50%;
  right: 40px;
  cursor: pointer;
}

.price-panel-mob .arrow {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  background-image: url("img/arrow-down-icon.svg");
  background-size: 16px 9px;
  background-position: center;
  background-repeat: no-repeat;
}

.price-panel-mob .closex {
  display: none;
  width: 28px;
  height: 28px;
  margin-top: -14px;
  text-align: center;
  font-size: 1.3em;
  font-weight: 300;
  line-height: 26px;
  border: 1px solid #d7d7d7;
  color: #c1c1c1;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  border-radius: 30px;
}

.page-scrolled .price-panel-mob {
  /*top:;*/
}

.page-scrolled .price-panel-mob .logo {
  visibility: visible;
}

.mobile-price-info-open .price-panel-mob .closex {
  display: block !important;
}

.mobile-price-info-open .price-panel-mob .sum,
.mobile-price-info-open .price-panel-mob .smalltext,
.mobile-price-info-open .price-panel-mob .arrow {
  display: none !important;
}

.admin-bar .price-panel-mob {
  /*top: 115px;*/
}

@include media($mobile1100) {
  .price-panel-mob {
    display: block;
    top: -100%;
  }
}

