.sign-up {
	background-color: #fff;

	.wrapper {
		padding-top: rem(32);
		padding-bottom: rem(128);
		.sign-heading {
			h1 {
				font-size: 2rem;
				line-height: 1.2em;
				font-weight: normal;
				margin-top: 0.5rem;
				color: #292d33;
			}
		}
	}

	.logo {
		display: inline-block;
		width: 52px;
		height: 66px;

		background-image: url('img/vertical-logo.svg');
		background-size: 52px 66px;

		margin-bottom: rem(32);
	}

	.top.content {
		@include span-columns(6);
		@include shift(3);

		text-align: center;

		h2 {
			text-align: center;
		}

		@include media($mobile768) {
			@include span-columns(12);
			@include shift(0);
		}
	}

	.loading.content {
		padding: rem(32) 0;
		text-align: center;

		@include span-columns(5);
		@include shift(3.5);

		@include media($mobile) {
			@include span-columns(12);
			@include shift(0);
		}
	}

	.api-error.content {
		position: relative;
		padding: rem(24) rem(80) rem(24) rem(24);
		//font-size: rem(16);
		color: $red;
		background-color: $light-bg-color;
		//text-align: 
		margin-bottom: rem(32);
		border: 1px solid $light-bg-color-hover;

		@include span-columns(5);
		@include shift(3.5);

		@include media($mobile) {
			@include span-columns(12);
			@include shift(0);
		}

		.body {
			margin: 0;
		}

		.code {
			margin: 0;
			margin-top: rem(8);
			font-size: rem(12);
			color: $light-font-color;
		}

		.close-button {
			position: absolute;
			top: 0;// rem(16);
			right: 0;// rem(16);
			padding: rem(16) rem(24);
			//background-color: #fff;
			color: $medium-font-color;
			cursor: pointer;

			&:hover {
				color: #000;
			}
		}
	}

	.form.content {
		@include span-columns(5);
		@include shift(3.5);

		@include media($mobile768) {
			@include span-columns(12);
			@include shift(0);
		}

		.no-numbers {
			padding: rem(32) 0;
			font-style: italic;
		}

		.list-wrapper {
			position: relative;

			.loading-overlay {
				position: absolute;
				top: 50%;
				left: 50%;
				right: auto;
				bottom: auto;
				width: 32px;
				height: 32px;
				background-color: rgba(255, 255, 255, 0.5);
				z-index: 1;
				background-image: url(img/loader_white.gif);
				background-repeat: no-repeat;
				background-position: center;
			}
		}

		.number-list {
			margin-top: rem(24);

			li {
				position: relative;
				background-color: $light-bg-color;
				margin-bottom: rem(16);
				line-height: rem(24);

				.basic-info {
					padding: rem(16);

					.number {
						color: $dark-font-color;
					}

					.info-button {
						width: rem(20);
						height: rem(20);
						position: absolute;
						right: rem(22);
						top: rem(30);
						cursor: pointer;

						background-image: url('img/money-icon.svg');
						background-size: 20px 20px;

						opacity: 0.25;

						@include transition(all 0.2s);
						z-index: 20;

						.no-touch &:hover, &.active {
							opacity: 1;
						}
					}
				}

				.price-info {
					box-shadow: 0 0 4px #ccc;
					position: absolute;
					background: #fff;
					z-index: 21;
					width: 100%;

					&:before {
						z-index: 1;
						content: "";
						display: block;
						position: absolute;
						width: 15px;
						height: 15px;
						background: #fff;
						top: -8px;
						right: 24px;
						box-shadow: 0 0 4px #ccc;
						transform: rotate(45deg);
					}

					&:after {
						z-index: 2;
						content: "";
						display: block;
						position: absolute;
						width: 100%;
						height: 0px;
						background: transparent;
						top: 0px;
						right: 0px;
						border-bottom: 15px solid #fff;
					}


					.remove-button {
						color: $red;
						font-size: rem(12);
						cursor: pointer;
					}
				}
			}
		}

		.price-info {
			padding: rem(16);
			
			table {
				width: 100%;
			}

			thead {
				th {
					font-weight: normal;
					color: $dark-font-color;
					padding-bottom: rem(8);
				}

				th:first-child {
					text-align: left;
				}
				th:last-child {
					text-align: right;
				}
			}

			td:last-child {
				text-align: right;
				color: $dark-font-color;
			}

			td:only-child {
				text-align: left;
				color: $dark-font-color;
			}
		}

		.cancel-button {
			cursor: pointer;
			color: #f24a4a;
		}

		.add-button {
			//text-align: right;
			font-size: rem(12);
			color: $dark-font-color;
			
			div {
				display: inline-block;
				line-height: 2rem;
				float: right;
				cursor: pointer;
			}
		}

		hr {
			margin: rem(16) 0 0 0;
		}

		.add-form {
			.summary {
				background-color: $light-bg-color;
				padding: 0 0 rem(24) 0;
				line-height: rem(24);
			}

			div.buttons {
				text-align: center;
				padding-top: rem(24);

				div {
					margin: 0 rem(16);
					cursor: pointer;
					display: inline-block;
					line-height: rem(40);
					height: rem(38.4);
				}

				.cancel {
					font-size: rem(12);
				}

				.submit {
					text-transform: uppercase;
					border: 1px solid $dark-font-color;
					border-radius: rem(80);
					//font-family: $demi-font;
					font-size: rem(9);
					color: $dark-font-color;
					letter-spacing: rem(1.24992);
					padding: 0 rem(24);

					&.disabled {
						border: 1px solid $light-font-color;
						color: $light-font-color;
					}
				}
			}

			.restrictions {
				color: $dark-font-color;
				background-color: $light-bg-color;
				//border: 1px solid rgba(215, 215, 215, 0.65);
				font-size: rem(14);
				padding: 0 rem(16);
				display: inline-block;
				width: 100%;

				ul {
					list-style-type: disc;
				}

				li {
					margin: rem(16) 0;
					margin-left: rem(16);
				}
			}

			.info-link {
				font-size: rem(12);
				color: $light-font-color;
				//border-bottom: 1px solid $light-font-color;
				text-decoration: underline;
				cursor: pointer;
				margin-top: rem(8);
				margin-bottom: rem(16);
				display: block;
			}
		}

		.submit-button {
			width: 100%;
			padding: rem(32);
		}

		.smalltext {
			padding-top: rem(16);
			font-size: rem(12);

			p {
				margin: 0;
			}

			a {
				color: $medium-font-color;
				text-decoration: underline;
			}
		}
	}

	.inputs {
		margin-top: rem(16);
		.input input {
			margin-top: -1px;
		}
	}

	.error {
		color: $red;
		font-size: rem(12);
		margin-top: rem(16);
	}

	.input {
		position: relative;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		.valuebox {
			padding: 0.6rem 1.5rem 0.5rem 1rem !important;
		}
		input {
			height: rem(70);
			background-repeat: no-repeat;
			background-position: rem(24) center;
			line-height: rem(70);
			padding: 1.25rem 1.3rem 1.35rem;
			width: 100%;
			border: 1px solid #dcdcdc;

			&.input-error {
				border-color: #f24a4a !important;
			}



			&.input-error {
				font-size: 1.2rem;
				line-height: 1.2rem;
				color: #000000;
				border-color: #000000;
			/*	border-color: #000000;*/
				z-index: 5;
				padding: 1.50rem 1.5rem 1.0rem 1.5rem;
				padding: 1.50rem 1.5rem 0rem 1.2rem;
			}

		}

		&:last-child input {
			border-bottom: 1px solid #dcdcdc;
		}

	}
	.beforeInputs {
		text-align: center;
	}
}

.rtl.sign-up .form.content {
	.price-info thead {
		th {
			&:last-child {
				text-align: left;
			}
			&:first-child {
				text-align: right;
			}
		}
	}
	.number-list li .basic-info {
		unicode-bidi: bidi-override !important;
		.info-button {
			left: 1.375rem;
			right: auto;
		}
	}
	input {
		text-align: right;
		unicode-bidi: bidi-override !important;
	}
}

.floating-placeholder {
	input {
		position: relative;
		background: transparent;
		z-index: 2;
	}
	label {
		 display: block;
		 position: absolute;
		 top: 1.5rem;
		 left: 1.3rem;
		 font-size: 20pt;
		 z-index: 1;
		 -moz-transform-origin: 0 0em;
		 -ms-transform-origin: 0 0em;
		 -webkit-transform-origin: 0 0em;
		 transform-origin: 0 0em;
		 -moz-transition: -moz-transform 160ms, color 200ms;
		 -o-transition: -o-transform 160ms, color 200ms;
		 -webkit-transition: -webkit-transform 160ms, color 200ms;
		 transition: transform 160ms, color 200ms;
		 -moz-transform: scale(1, 1) rotateY(0) translate(0, 0);
		 -ms-transform: scale(1, 1) rotateY(0) translate(0, 0);
		 -webkit-transform: scale(1, 1) rotateY(0) translate(0, 0);
		 transform: scale(1, 1) rotateY(0) translate(0, 0);

		.sign-up & {
			font-size: 14pt;
		}

		.rtl & {
			left: auto;
			right: 1.3rem;
		}
	}
}


.floating-placeholder-float {
	label {
		font-family: 'Roboto', sans-serif;
		font-size: 11pt;
		font-weight: 700;
		color: #000000;
		-moz-transform: translate(0, -0.7rem);
		-ms-transform: translate(0, -0.7rem);
		-webkit-transform: translate(0, -0.7rem);
		/*transform: scale(0.55, 0.55) rotateY(0) translate(0,-1.5rem);*/
		transform: translate(0, -0.7rem);
		.sign-up & {
			font-size: 11pt;
		}

	}
	input {
		.sign-up & {
			position: relative;
			z-index: 4;

			font-size: 1.2rem;
			line-height: 1.2rem;
			color: #000000;
			/*	border-color: #000000;*/
			z-index: 5;
			padding: 1.50rem 1.5rem 0rem 1.2rem;
			&:focus {
				border: 1px solid #000000 !important;
				border-bottom: 1px solid #000000 !important;
				position: relative;
				z-index: 5;
			}
		}
	}
}
