.slider {
	position: relative;
	width: 100%;
	height: rem(17);

	.horline {
		position: absolute;
		width: 100%;
		height: rem(1);
		background-color: $hr-color;
		top: rem(8);
	}

	.vertlines {
		position: absolute;
		left: rem(9);
		right: rem(9);
	}

	.vertline {
		position: absolute;
		height: rem(17);
		width: rem(1);
		background-color: $hr-color;
		margin-left: - rem(1);

		@for $i from 1 through 5 {
			&:nth-child(#{$i}) {
				left: 10% + (20% * ($i - 1));
			}
		}
	}

	.fill {
		position: absolute;
		height: rem(3);
		border-radius: rem(3);
		background-color: $red;
		top: rem(7);
	}

	.handle {
		width: rem(18);
		height: rem(18);
		border-radius: rem(9);
		background-color: $red;
	}
}