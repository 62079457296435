@function new-breakpoint($query:$feature $value $columns, $total-columns: $grid-columns) {

  @if length($query) == 1 {
    $query: $default-feature nth($query, 1) $total-columns;
  }

  @else if length($query) % 2 == 0 {
    $query: append($query, $total-columns);
  }

  @if not belongs-to($query, $visual-grid-breakpoints) {
    $visual-grid-breakpoints: append($visual-grid-breakpoints, $query, comma);
  }

  @return $query;
}
