.layout-part.layout-header-hr-text {
	.content {
		@include span-columns(6);
		@include shift(3);

		padding-top: rem(128);
		padding-bottom: rem(128);
		text-align: center;

		h2 {
			margin-bottom: rem(6);
		}

		@include media($mobile) {
			@include span-columns(12);
			@include shift(0);

			padding-top: rem(64);
			padding-bottom: rem(64);
		}
	}
}