$max-width: 1176px !global;
$column: em(76) !global;
$gutter: em(24) !global;
$visual-grid: false;

$default-layout-direction: LTR !global;
//$default-layout-direction: RTL !global;

$regular-font: 'Montserrat Regular';
$demi-font: 'Montserrat SemiBold';

$dark-font-color: #292d33;
$medium-font-color: #7e7e94;
$light-font-color: #c9ccd1;

$hr-color: #e7e9ee;

$black: #1f2225;
$red: #f24a4a;
$dark-red: #c93535;
$gold: #d4a827;

$dark-bg-color: #292d33;
$light-bg-color: #f8f9fC;
$light-bg-color-hover: #f0f1f4;

$hero-content-color: #fff;

$lang-menu-bg-color: $dark-bg-color;
$lang-menu-separator-color: $black;
$lang-menu-font: $regular-font;
$lang-menu-link-color: $medium-font-color;

$cta-button-font-size: 12;
$cta-button-bg-color: $red;
$cta-button-font-color: #fff;
$cta-button-hover-bg-color: $dark-red;
$cta-button-hover-font-color: #fff;
$cta-disabled-bg-color: #cbcdce;
$cta-disabled-font-color: #929697;

$modal-font-dark: #24232b;
