@mixin shift($n-columns: 1) {
  @include shift-in-context($n-columns);
}

@mixin shift-in-context($shift: $columns of $container-columns) {
  $n-columns: nth($shift, 1);
  $parent-columns: container-shift($shift);

  $direction: get-direction($layout-direction, $default-layout-direction);
  $opposite-direction: get-opposite-direction($direction);

  margin-#{$opposite-direction}: $n-columns * flex-grid(1, $parent-columns) + $n-columns * flex-gutter($parent-columns);

  // Reset nesting context
  $parent-columns: $grid-columns;
}
