.layout-part.layout-type-03 {

	.container {
		padding: rem(224) 0;

		@include media($mobile) {
			padding: rem(88) 0;
		}
	}

	.background-image {
		@include shift-pos-left(7 of 12);

		//background-attachment: fixed;

		.touch & {
			background-attachment: scroll;
		}

		@include media($mobile) {
			display: none;
		}
	}

	.content-text {
		@include span-columns(5);
		@include shift(7);

		@include media($mobile) {
			@include span-columns(12);
			@include shift(0);
		}
	}

}