html, body {
	color: $medium-font-color;
	background-color: $dark-bg-color;

	font-family: $regular-font;
	font-weight: 300;
	line-height: 1.4rem;
	
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;

	width: 100%;
	height: 100%;
	
	overflow: hidden;
}

/* WDP-904 - hide google recaptcha banner on the site */
.grecaptcha-badge { visibility: hidden; }

h1 {
	font-size: rem(48);
	line-height: 1em;
	margin-top: 0;
	margin-bottom: 24px;
	font-weight: normal;

	@include media($mobile) {
		font-size: rem(32);
	}
}

h2 {
	font-size: rem(32);
	line-height: 1.2em;
	font-weight: normal;
	margin-top: rem(8);

	color: $dark-font-color;

	@include media($mobile) {
		font-size: rem(32);
	}
}

h3 {
	font-size: rem(24);
	line-height: 1em;
}

h4 {
	font-size: rem(18);
	line-height: 1.4em;
	margin-top: rem(8);
	margin-bottom: rem(12);

	color: $dark-font-color;
}

a, .link {
	color: $red;
	text-decoration: none;
	cursor: pointer;
	outline: 0;
}

a:hover, a:active, a:focus {
	outline: 0;
}

ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

input, textarea {
	//font-family: $regular-font;
	resize: none;

	border-radius: 0;
	-webkit-appearance: none;
}

hr {
	border: 0;
	width: 100%;
	height: 1px;
	background-color: rgba(151, 151, 151, 0.22);
	margin: rem(64) 0;

	@include media($mobile) {
		margin: rem(32) 0;
	}
}

.small-thick-hr {
	display: inline-block;
	width: rem(92);
	height: 4px;
	background-color: $hr-color;
}

#wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;

	background-color: rgba(255, 0, 0, 0.01);
}

div.scroll-wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0%;

	opacity: 0.99;

	overflow-y: auto;
	overflow-x: hidden;
	box-sizing: content-box;

	@include transition(left 0.2s ease-in-out);

	&.mobile-menu-open {
		left: -65%;
		padding-right: 65%;
	}

	.touch & {
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}
}

.mobile-background-image {
	display: none;

	position: relative;
	width: 100%;
	height: rem(480);
	background-size: cover;

	@include media($mobile) {
		display: block;
	}
}

div.wrapper {
	@include fill-parent;
	max-width: 1680px;
	position: relative;
	padding: 0 rem(32);
	margin: 0px auto;
	background-color: #fff;

	@include media($mobile) {
		padding: 0 rem(24);
	}

	.background-image {
		position: absolute;
		//height: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		background-size: cover;
		background-position: center;

		.help & {
			right: auto;
		}

	}

	.background-video {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		overflow: hidden;

		@include transition(all 0.5s ease-in-out);

		&.hidden {
			opacity: 0;
			visibility: hidden;

			@include transition(all 0.5s ease-in-out);
		}
	}

	.dark-overlay {
		position: absolute;
		//height: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		background-color: rgba(36, 39, 45, 0.85);
	}
}

div.container { @include outer-container; }

.span-1 { @include span-columns(1); }
.span-2 { @include span-columns(2); }
.span-3 { @include span-columns(3); }
.span-4 { @include span-columns(4); }
.span-5 { @include span-columns(5); }
.span-6 { @include span-columns(6); }
.span-7 { @include span-columns(7); }
.span-8 { @include span-columns(8); }
.span-9 { @include span-columns(9); }
.span-10 { @include span-columns(10); }
.span-11 { @include span-columns(11); }
.span-12 { @include span-columns(12); }

.shift-1 { @include shift(1); }
.shift-2 { @include shift(2); }
.shift-3 { @include shift(3); }
.shift-4 { @include shift(4); }
.shift-5 { @include shift(5); }
.shift-6 { @include shift(6); }
.shift-7 { @include shift(7); }
.shift-8 { @include shift(8); }
.shift-9 { @include shift(9); }
.shift-10 { @include shift(10); }
.shift-11 { @include shift(11); }

.omega { @include omega; }

.center {
	text-align: center;
}

.cta-button {
	// LocalFontSize
	background-color: $cta-button-bg-color;
	color: $cta-button-font-color;
	display: inline-block;
	font-family: $demi-font;
	font-size: rem($cta-button-font-size);
	text-decoration: none;
	text-transform: uppercase;
	//white-space: nowrap;
	text-align: center;
	cursor: pointer;
	letter-spacing: 1px;
	border: none;

	margin-top: rem(16);

	@include transition(all 0.2s ease-in-out);

	&.inline {
		height: rem(56);
		line-height: rem(56);
		padding: rem(1) rem(24) 0 rem(24);
		border-radius: rem(4);
	}

	&.big {
		min-width: rem(250);
		//height: rem(64);
		border-radius: rem(32);
		//line-height: rem(64);
		padding: rem(21.6) rem(16) rem(20.8);
	}

	&.outlined {
		border: 2px solid $red;
		color: $red;
		background-color: transparent;
		padding: rem(16) rem(64);
		font-size: rem($cta-button-font-size + 2);

		&:hover {
			border-color: $cta-button-hover-bg-color;
		}
	}

	&:hover {
		color: $cta-button-hover-font-color;
		background-color: $cta-button-hover-bg-color;
	}

	&.disabled {
		background-color: $cta-disabled-bg-color;
		color: $cta-disabled-font-color;
		cursor: inherit;
	}
}