.article {
	.content {
		.wp-caption {
			width: auto !important;
		}
		img {
			&,
			&[class^=wp-image] {
				max-width: 100%;
				width: auto;
				height: auto;
			}
		}
	}
}

.article.container {
	padding-top: rem(128);
	padding-bottom: rem(0);

	font-size: rem(18);
	line-height: rem(30);

	@include media($mobile) {
		padding-top: rem(32);
	}

	.content {
		@include span-columns(8);
		@include shift(2);

		@include media($mobile) {
			@include span-columns(12);
			@include shift(0);
		}

		@include media($tablet-portrait) {
			@include span-columns(10);
			@include shift(1);
		}

		&.social {
			padding-top: rem(32);
		}
	}

	.social.content {
		line-height: 0;

		> * {
			vertical-align: bottom;
			margin-right: rem(16);
		}
	}
	
	.alignleft {
		float: left;
		margin-top: rem(16);
		margin-right: rem(32);
		margin-bottom: rem(8);
	}

	.alignright {
		float: right;
		margin-top: rem(16);
		margin-left: rem(16);
		margin-bottom: rem(8);
	}

	.aligncenter {
		text-align: center;
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-top: rem(16);
		margin-bottom: rem(16);
	}

	.wp-caption-text {
		text-align: initial;
	}
	.size-full {
		max-width: 100%;
		height: auto;
	}

	div:not(.unordered-list-wrapper) ul li > *:not(strong):not(.item-header):not(.item-body),
	div:not(.ordered-list-wrapper) ol li > *:not(strong):not(.item-header):not(.item-body) {
		font-weight: normal;
	}

	h1, h2, strong {
		color: $black;
	}

	h1 {
		line-height: 1.22em;
		padding-bottom: rem(15);
	}
	ul, ol {
		padding-left: rem(32);
		font-weight: bold;
		color: #404346;
		padding-left: rem(18);
		margin-top: rem(30);
		margin-bottom: rem(30);

		@include media($mobile) {
			padding-left: rem(24);
		}
	}

	ul {
		padding-left: rem(17);
		list-style-type: disc;
		list-style-position: outside;
		li {
			padding-left: 3px;
		}
	}

	ol {
		padding-left: rem(18);
		list-style-position: outside;
	}

	ul li, ol li {
		margin-right: 0;
		margin-top: rem(7);
		margin-bottom: rem(11);
		line-height: rem(30);
		padding-left: rem(5);
	}

	iframe {
		width: 100%;
		display: inline-block;
	}
}