.layout-part.layout-type-02 {

	.container {
		text-align: center;
		padding: rem(224) 0;

		@include media($mobile) {
			padding: rem(88) 0;
		}

		> div {
			@include span-columns(8);
			@include shift(2);

			@include media($mobile) {
				@include span-columns(12);
				@include shift(0);
			}
		}

		h2 {
			color: #fff;
			margin-bottom: rem(24);
		}

		.line-container {
			text-align: center;
			line-height: 0;

			.line {
				background-color: $medium-font-color;
				width: rem(96);
				height: rem(4);
				display: inline-block;
			}
		}

		.text {
			@include span-columns(6 of 12);
			@include shift(3);

			@include media($mobile) {
				@include span-columns(12);
				@include shift(0);
			}

			margin-top: rem(24);

			p:first-child {
				margin-top: 0;
			}
		}
	}

}