.mobile-close-hitarea {
	position: absolute;
	z-index: 1000;

	background-color: rgba(0, 0, 0, 0.01);

	width: 35%;
	height: 100%;

	display: none;

	&.open {
		display: block;
	}
}

.mobile-nav {
	position: absolute;
	z-index: 1;
	left: 100%;
	width: 65%;
	height: 100%;

	overflow-y: auto;

	background-color: $dark-bg-color;

	@include transition(left 0.2s ease-in-out);

	&.open {
		left: 35%;
	}

	ul {
		padding: rem(16);

		li {
			padding: rem(16);
			font-size: rem(12);

			a {
				color: #fff;
			}
		}
	}

	.menu {
		// position: absolute;

		li {
			text-transform: uppercase;
			//font-family: $demi-font;
			
			a {
				color: #fff;
			}
		}

		&.hidden {
			display: none;
		}
	}

	.language-menu {
		position: absolute;

		li {
			a {
				color: $medium-font-color;
			}
		}

		&.hidden {
			display: none;
		}
	}

	.language-button {
		padding: rem(16);
		// position: absolute;
		left: rem(16);
		bottom: rem(8);
		font-size: rem(12);

		&.hidden {
			//display: none;
		}
	}

	.lang-mobile-menu .header-summary{
		padding:0rem 0.4rem;
	}
	.lang-mobile-menu .summary .currency-dropdown div span{font-size:13px;}
	.lang-mobile-menu .summary .currency-dropdown__flag{margin-right:15px;}
	.mobile-nav .lang-mobile-menu .language-button{padding:1rem 1rem 0.5rem;}

	.lang-mobile-menu{
		position: absolute;
   		bottom: 12px;
	}

	div.flag-icon {
		display: inline-block;
		width: 16px;
		height: 16px;
		background-color: #000;
		border-radius: 8px;
		vertical-align: middle;

		background-size: cover;
		background-position: 25%;
		margin-right: 16px;
	}

}