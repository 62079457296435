.light-top div.nav ul.menu > li > div.toggle,
.dark-top div.nav ul.menu > li > div.toggle {
    font-size: 0;
}

@media screen and (max-width: 1100px) {
    .light-top div.nav ul.menu > li > a, .light-top div.nav ul.menu > li > div.toggle {
        color: #fff;
    }

    div.nav ul.menu > li {
        display: block;
        float: none;
        padding: 11px 5px;
    }

    div.nav ul.menu > li[language-menu] {
        position: fixed;
        bottom: 0;
        left: 35%;
    }

    .light-top div.nav ul.menu > li > div.toggle div.flag-icon,
    .dark-top div.nav ul.menu > li > div.toggle div.flag-icon {
        margin-right: 10px;
    }

    .light-top div.nav ul.menu > li > div.toggleб
    .dark-top div.nav ul.menu > li > div.toggle {
        text-transform: none;
    }

    .mobile-nav a,
    .light-top div.nav ul.menu > li > a,
    .light-top div.nav ul.menu > li > div.toggle,
    .dark-top div.nav ul.menu > li > div.toggle,
    .dark-top div.nav ul.menu > li > a {
        font-size: 12px;
        letter-spacing: -.2px;
    }

    div.nav ul.menu {
        position: fixed;
        right: 0;
        top: 0;
        width: 65%;
        display: none;
        height: 100%;
        z-index: -1;
        text-align: left;
    }

    .mobile-menu-open div.nav ul.menu {
        display: block;
    }

    .light-top div.nav ul.menu > li > a.try-free-button, .light-top div.nav ul.menu > li > div.toggle.try-free-button,
    .dark-top div.nav ul.menu > li > a.try-free-button, .dark-top div.nav ul.menu > li > div.toggle.try-free-button {
        border: none;
        padding: 0;
    }

    .light-top div.nav ul.menu > li > a.try-free-button:hover, .light-top div.nav ul.menu > li > div.toggle.try-free-button:hover,
    .dark-top div.nav ul.menu > li > a.try-free-button:hover, .dark-top div.nav ul.menu > li > div.toggle.try-free-button:hover {
        background: transparent;
        color: #fff;
        border: none;
    }

    div.nav ul.language-menu {
        bottom: 42px;
        left: -8px;
    }
}

.prices-international .table.wrapper .content {
    position: relative;
}

.page-template-prices-international .currency-dropdown {
    // top: -70px;
    z-index: 10;
}

.page-template-prices-international .currency-dropdown.plan-dropdown {
    right: 10rem;
    width: 11rem;
    font-size: 1rem;
}

.page-template-prices-international .currency-dropdown.plan-dropdown div {
    padding: 0.8rem 1rem 0.8rem 1rem;
}


.page-template-prices-international .currency-dropdown.plan-dropdown div span {
    display: inline-block;
    width: 100%;
    padding: 0;
}

.page-template-prices-international .currency-dropdown.plan-dropdown li {
    padding: 0.8rem 1rem 0.8rem 1rem;
    background-color: #fff;
}

.page-template-prices-international .container tr:nth-child(2n+3) {
    background: #F8F9FC;
}

input.hidden {
    opacity: 0;
    top: 0;
    position: absolute;
}

.prices-numbers .form .restrictionsInfo {
    /*text-align: center;*/
}

#IntlCallsPage .content .subheader,
#IntlCallsPage table th,
#IntlCallsPage .currency-block {
    color: #a1a2b3;
}

#IntlCallsPage .currency-block .currency-dropdown {
    color: #292d33;
}

#IntlCallsPage table th:first-child,
#IntlCallsPage table td:first-child {
    padding-left: 15px;
}

#IntlCallsPage table th:last-child,
#IntlCallsPage table td:last-child {
    padding-right: 15px;
}

/* **** Contacts Support Numbers **** */
.contact .supportNumberFullList.wrapper {
    padding-bottom: 0;
}

.contact .supportNumberFullList .container {
    padding-bottom: 4rem;
    border-bottom: 1px solid #EAEAEC;
}

.contact .supportNumberFullList ul li {
    margin: 0 0 20px 0;
}

.contact .supportNumberFullList ul li .country-name {
    color: #000;
    font-weight: bold;
}

.contact .supportNumberFullList ul li .tel {
    color: #999;
}

.rtl.contact .supportNumberFullList ul li .tel {
    unicode-bidi: bidi-override !important;
}

.toggleAllNumbers {
    color: #999;
    cursor: pointer;
    font-size: 0.4em;
    text-decoration: underline;
}

.loader {
    width: 32px;
    height: 32px;
    margin: auto;
    background-image: url("img/loader_white.gif");
    background-repeat: no-repeat;
    background-position: center center;
}

.fleft {
    float: left;
}

.fright {
    float: right;
}

.fnone {
    float: none;
}

.clear {
    clear: both;
}
.table-relative{
    position: relative;
}
@media screen and (max-width: 768px) {
    .free-plan-dropdown{
        position: absolute;
        top: -60px;
        right: 0 !important;
    }
}

@media screen and (min-width: 481px) {
    .contact .supportNumberFullList .col {
        float: left;
    }

    .contact .supportNumberFullList.colCount-2 .col {
        width: 50%;
    }

    .contact .supportNumberFullList.colCount-3 .col {
        width: 33%;
    }

    .contact .supportNumberFullList.colCount-4 .col {
        width: 25%;
    }

    .contact .supportNumberFullList.colCount-5 .col {
        width: 20%;
    }

    .contact .supportNumberFullList .container {
        padding-bottom: 6rem;
    }
}

/* **** Price Numbers Full List **** */
.prices-numbers-list .table.wrapper .loading {
    position: absolute;
    bottom: 120%;
    left: 45%;
}

.prices-numbers-list .table.wrapper .country-search {
    width: 30%;
    margin-left: 12rem;
}

.rtl.prices-numbers-list .table.wrapper .country-search {
    margin-right: 12rem;
    margin-left: auto;
}

.prices-numbers-list .table.wrapper .country-search .dropdown {
    float: none;
    margin: 0;
    height: 3.1rem;
    line-height: 3.1rem;
    width: 100%;
}

.prices-numbers-list .table.wrapper .country-search .dropdown .list {
    top: 3.1rem;
    z-index: 3;
    line-height: 1.4rem;
}

.prices-numbers-list .table.wrapper .country-search .dropdown .input {
    left: auto;
    right: 0;
    width: 100%;
    border-right: 1px solid rgba(215, 215, 215, 0.65);
}

.prices-numbers-list .table.wrapper .country-search .dropdown .input .suggestion {
    padding-top: 0.6rem;
    padding-bottom: 0.8rem;
    padding-left: 2rem;
}

.prices-numbers-list .table.wrapper .country-search .dropdown .input .suggestion .end {
    color: #9797A0;
}

.prices-numbers-list .table.wrapper .country-search .dropdown .input input {
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-left: 2rem;
}

.prices-numbers-list .table.wrapper .country-search .dropdown .button {
    right: auto;
    left: 0;
    width: 1.5rem;
    background-color: transparent;
}

.prices-numbers-list .table.wrapper .country-search .dropdown .button .icon {
    background-image: url("img/search-icon-grey.svg");
    /* background-size: 70% auto; */
    background-position: 8px center;
}

.prices-numbers-list .table.wrapper .currency-block .currency-dropdown {
    font-size: 1rem;
    right: 12rem;
}

.page-template-bring-old-numbers .table.wrapper .currency-block .currency-dropdown,
.page-template-prices-international .table.wrapper .currency-block .currency-dropdown {
    font-size: 1rem;
    right: 0;
}

.rtl.prices-numbers-list .table.wrapper .currency-block .currency-dropdown,
.rtl.page-template-bring-old-numbers .table.wrapper .currency-block .currency-dropdown {
    right: auto;
    left: 0;
}

.prices-numbers-list .table.wrapper .currency-block .currency-dropdown div,
.page-template-bring-old-numbers .table.wrapper .currency-block .currency-dropdown div,
.page-template-prices-international .table.wrapper .currency-block .currency-dropdown div {
    padding: 0.8rem 1rem 0.8rem 1rem;
}

.prices-numbers-list .table.wrapper .currency-block .currency-dropdown ul,
.page-template-bring-old-numbers .table.wrapper .currency-block .currency-dropdown ul,
.page-template-prices-international .table.wrapper .currency-block .currency-dropdown ul {
    background: #FFF;
}

.prices-numbers-list .table.wrapper .currency-block .currency-dropdown ul li,
.page-template-bring-old-numbers .table.wrapper .currency-block .currency-dropdown ul li,
.page-template-prices-international .table.wrapper .currency-block .currency-dropdown ul li {
    padding-top: 1.5rem;
    padding-left: 1rem;
}

.prices-full-numbers-list .table.wrapper .currency-block .currency-dropdown ul li,
.prices-bring-old-numbers .table.wrapper .currency-block .currency-dropdown ul li,
.page-template-prices-international .table.wrapper .currency-block .currency-dropdown ul li {
    padding-right: 1.875rem;
}

.prices-numbers-list .table.wrapper .currency-block .currency-dropdown ul li:first-child,
.page-template-bring-old-numbers .table.wrapper .currency-block .currency-dropdown ul li:first-child,
.page-template-prices-international .table.wrapper .currency-block .currency-dropdown ul li:first-child {
    padding-top: 0.8rem;
}

.prices-numbers-list .table.wrapper .currency-block .currency-dropdown ul li:last-child,
.page-template-bring-old-numbers .table.wrapper .currency-block .currency-dropdown ul li:last-child,
.prices-numbers-list .table.wrapper .number-type-dropdown ul li:last-child,
.page-template-prices-international .table.wrapper .currency-block .currency-dropdown ul li:last-child {
    padding-bottom: 11px;
}

.prices-numbers-list .table.wrapper .number-type-dropdown {
    right: auto;
    left: 0;
    top: 0;
    width: 11rem;
    font-size: 1rem;
    z-index: 2;
}

.rtl.prices-numbers-list .table.wrapper .number-type-dropdown {
    right: 0;
    left: auto;
}

.prices-numbers-list .table.wrapper .number-type-dropdown div {
    padding: 0.8rem 0.625rem 0.8rem 0.625rem;
    background-image: url("img/small-arrow-down-icon.svg");
    background-size: 9px 5px;
    background-repeat: no-repeat;
    background-position: 95% center;
}

.prices-numbers-list .table.wrapper .number-type-dropdown div span {
    background: none;
}

.prices-numbers-list .table.wrapper .number-type-dropdown ul {
    background: #FFF;
}

.prices-numbers-list .table.wrapper .number-type-dropdown ul li {
    padding-top: 1.5rem;
    padding-left: 1rem;
}

.prices-numbers-list .table.wrapper {
    padding-top: 66px;
}

.prices-numbers-list .table.wrapper table {
    margin-top: 4rem;
}

.prices-numbers-list .table.wrapper table tr:nth-child(2n+3) {
    background: #F8F9FC;
}

.prices-numbers-list .table.wrapper .countryPriceList tr th:nth-child(1),
.prices-numbers-list .table.wrapper .countryPriceList tr td:nth-child(1) {
    width: 17%;
    text-align: left;
    vertical-align: top;
}

.prices-numbers-list .table.wrapper .countryPriceList tr th:nth-child(2),
.prices-numbers-list .table.wrapper .countryPriceList tr td:nth-child(2) {
    width: 23%;
    text-align: left;
    vertical-align: top;
}

.prices-numbers-list .table.wrapper .countryPriceList tr th:nth-child(3),
.prices-numbers-list .table.wrapper .countryPriceList tr th:nth-child(4),
.prices-numbers-list .table.wrapper .countryPriceList tr th:nth-child(5),
.prices-numbers-list .table.wrapper .countryPriceList tr td:nth-child(3),
.prices-numbers-list .table.wrapper .countryPriceList tr td:nth-child(4),
.prices-numbers-list .table.wrapper .countryPriceList tr td:nth-child(5) {
    width: 20%;
    text-align: right;
    vertical-align: top;
}

.rtl.prices-numbers-list .table.wrapper .countryPriceList tr th,
.rtl.prices-numbers-list .table.wrapper .countryPriceList tr td {
    text-align: right;
}

.rtl.prices-numbers-list .table.wrapper .countryPriceList tr th,
.rtl.prices-numbers-list .table.wrapper .countryPriceList tr td:nth-child(2) {
    unicode-bidi: bidi-override !important;
}

@media screen and (max-width: 480px) {
    .prices-numbers-list .table.wrapper .country-search,
    .rtl.prices-numbers-list .table.wrapper .country-search {
        width: 100%;
        margin: auto;
    }

    .prices-numbers-list .table.wrapper .currency-dropdown,
    .page-template-bring-old-numbers .table.wrapper .currency-dropdown {
        right: 0;
        top: 10rem;
        width: 100%;
    }

    .page-template-bring-old-numbers .table.wrapper .currency-dropdown {
        top: -2rem;
    }

    .prices-numbers-list .table.wrapper .number-type-dropdown {
        top: 5rem;
    }

    .prices-numbers-list .table.wrapper .number-type-dropdown ul li {
        padding-left: 0.625rem;
    }

    .prices-numbers-list .table.wrapper .currency-block .currency-dropdown ul li:first-child,
    .page-template-bring-old-numbers .table.wrapper .currency-block .currency-dropdown ul li:first-child {
        padding-top: 0.8rem;
    }

    .prices-numbers-list .table.wrapper .currency-dropdown div,
    .page-template-bring-old-numbers .table.wrapper .currency-dropdown div,
    .prices-numbers-list .table.wrapper .currency-dropdown ul,
    .page-template-bring-old-numbers .table.wrapper .currency-dropdown ul {
        text-align: center;
    }

    .prices-numbers-list .table.wrapper .currency-block .currency-dropdown ul li,
    .page-template-bring-old-numbers .table.wrapper .currency-block .currency-dropdown ul li {
        padding-left: 0.625rem;
    }

    .prices-numbers-list .table.wrapper table {
        margin-top: 12rem;
    }
}

@media screen and (max-width: 480px) {
    .prices-numbers-list .table.wrapper .country-search,
    .rtl.prices-numbers-list .table.wrapper .country-search {
        width: 100%;
        margin: auto;
    }

    .prices-numbers-list .table.wrapper .currency-dropdown,
    .page-template-bring-old-numbers .table.wrapper .currency-dropdown,
    .page-template-prices-international  .table.wrapper .currency-dropdown,
    .page-template-prices-international  .table.wrapper .plan-dropdown {
        right: 0;
        top: 10rem;
        width: 100%;
    }

    .page-template-bring-old-numbers .table.wrapper .currency-dropdown {
        top: -2rem;
    }

    .prices-numbers-list .table.wrapper .number-type-dropdown {
        top: 5rem;
    }

    .prices-numbers-list .table.wrapper .number-type-dropdown ul li {
        padding-left: 0.625rem;
    }

    .prices-numbers-list .table.wrapper .currency-block .currency-dropdown ul li:first-child,
    .page-template-bring-old-numbers .table.wrapper .currency-block .currency-dropdown ul li:first-child {
        padding-top: 0.8rem;
    }

    .prices-numbers-list .table.wrapper .currency-dropdown div,
    .page-template-bring-old-numbers .table.wrapper .currency-dropdown div,
    .page-template-prices-international .table.wrapper .currency-dropdown div,
    .prices-numbers-list .table.wrapper .currency-dropdown ul,
    .page-template-bring-old-numbers .table.wrapper .currency-dropdown ul,
    .page-template-prices-international .table.wrapper .currency-dropdown ul {
        text-align: center;
    }

    .prices-numbers-list .table.wrapper .currency-block .currency-dropdown ul li,
    .page-template-bring-old-numbers .table.wrapper .currency-block .currency-dropdown ul li {
        padding-left: 0.625rem;
    }

    .prices-numbers-list .table.wrapper table {
        margin-top: 12rem;
    }
}

/* EU COOKIES LAW wp-plugin */
.pea_cook_wrapper {
    padding: 20px 25px !important;
    margin: auto !important;
    left: 0 !important;
    bottom: 0 !important;
    width: 100% !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
    border-radius: 0 !important;
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    -ms-box-sizing: border-box !important;
    -o-box-sizing: border-box !important;
    box-sizing: border-box !important;
}

#pea_cook_btn {
    width: auto;
    padding: 0.5em;
    line-height: 0.5em;
    position: absolute;
    top: 2px;
    right: 2px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
}

/* custom styles for ckeditor with element wrappers */
/* for images in articles */
.article-image-wrapper {
    background: #F7FAFA;
    padding: 20px;
    margin-top: 43px;
    margin-bottom: 44px;
}

.article-image-wrapper img {
    display: block;
    margin: 0 auto;
}

/* for ordered list in articles */
.ordered-list-wrapper ol {
    padding-left: 20px;
}

.ordered-list-wrapper ol li {
    color: #404346;
    font-weight: bold;
}

.ordered-list-wrapper ol li .item-header {
    display: inline;
}

.ordered-list-wrapper ol li .item-body {
    display: inline;
    color: #7e7e94;
    font-weight: normal;
}

/* for unordered list in articles */
.unordered-list-wrapper ul {
    padding-left: 20px;
}

.unordered-list-wrapper ul li {
    color: #404346;
    font-weight: bold;
}

.unordered-list-wrapper ul li .item-header {
    font-size: 20px;
}

.unordered-list-wrapper ul li .item-body {
    color: #7e7e94;
    margin: 1.5rem 0rem 1.5rem 1rem;
    font-weight: normal;
}

.unordered-list-wrapper ul > br,
.unordered-list-wrapper ul li > br,
.ordered-list-wrapper ol > br,
.ordered-list-wrapper ol li > br {
    display: none;
}

/* for italic text in articles */
.italic-font-wrapper em {
    background: none;
    border: none;
    color: #404346;
    margin: 0;
    font-style: italic;
    display: inline;
    padding: 0;
    font-weight: bold;
}

/* for blue block in articles */
.blue-wrapper {
    border: 1px solid #D6E5E5;
    background: #E7F9F9;
    padding: 1rem 1.5rem;
    margin: 1rem 0;
    line-height: 1.7rem;
}

.blue-wrapper p:first-child {
    margin-top: -1rem;
}

.blue-wrapper p:last-child {
    margin-bottom: -1rem;
}

/* contacts page */
@media screen and (min-width: 481px) {
    .contact .supportNumberFullList.colCount-3 .col {
        text-align: center;
    }
}

@media screen and (max-width: 481px) {
    .contact .supportNumberFullList.colCount-3 .col {
        width: 50%;
        float: left;
        text-align: left;
    }

    .contact .supportNumberFullList.colCount-3 .col.last {
        width: 100%;
    }

    .contact .supportNumberFullList.colCount-3 .col.last li {
        width: 50%;
        float: left;
    }

    .contact .top.wrapper .phone a {
        font-size: 26px;
    }

    .top.wrapper .content h1 {
        text-align: left;
    }

    .contact .top.wrapper .subheader {
        margin-left: 1%;
        margin-right: 1%;
        text-align: justify;
        width: 98%;
    }
}

/* **** Workable Job List style **** */
/* General */
.whr_enabled {
    position: relative;
    width: 100% !important;
    margin-left: 0 !important;
}

.whr_enabled .hidden {
    display: none !important;
}

.whr_enabled.single .job_listing_header {
    display: none;
}

.whr_enabled .job_listing_header {
    float: left;
    width: 30%;
    padding-right: 10%;
    box-sizing: content-box;
}

.whr_enabled .job_listing_header h2 {
    margin-top: 0;
}

.jobs .listing.wrapper {
    background-color: #fff;
}

/* Lists */
.whr_enabled .whr-description,
.whr_enabled:not(.single) .whr-items > *:not(.whr-item),
.whr_enabled.single .whr-item:not(.selected),
.whr_enabled:not(.whr_is_meta_info) .whr-item .whr-info,
.whr_enabled.single .whr-group {
    display: none;
}

.whr_enabled .whr-items {
    display: block !important;
}

.whr_enabled:not(.single) .whr-items:not(:last-child) {
    margin-bottom: 4em;
}

.whr_enabled .whr-item {
    margin: 0.2em 0;
}

.whr_enabled .whr-item .whr-title {
    margin: 0;
}

.whr_enabled .whr-item .whr-title > a {
    color: #7e7e94;
    font-weight: normal;
    font-size: 16px;
    text-decoration: underline !important;
}

.whr_enabled.single .whr-item .whr-title {
    margin-bottom: 1em;
    line-height: 1.4em;
}

.whr_enabled.single .whr-item .whr-title > a {
    cursor: default;
    color: #000;
    font-weight: normal;
    font-size: 1.4em;
    text-decoration: none !important;
}

.whr_enabled:not(.single) .whr-item .whr-title > a:hover,
.whr_enabled:not(.single) .whr-item .whr-title > a:focus {
    color: #f24a4a;
}

.whr_enabled .whr-item .whr-info {
    font-style: italic;
    font-size: 0.75em;
    color: #b1b1b1;
}

.whr_enabled .whr-group {
    font-size: 1.2em;
}

.whr_enabled .whr-group > a {
    cursor: default;
    color: #292d33;
    font-weight: bold;
}

.whr_enabled.single .jobs .whr-description,
.whr_enabled.descriptions .jobs .whr-description {
    display: block;
    color: #7e7e94;
}

.whr_enabled .whr-description strong {
    color: #333;
}

.whr_enabled .whr-description ul {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 17px;
}

.whr_enabled .jobs {
    float: right;
    width: 50%;
    padding-right: 10%;
    box-sizing: content-box;
    margin-top: 0 !important;
}

.whr_enabled.single .jobs {
    float: none;
    width: 100%;
    /* padding-right: 0; */
}

.whr_enabled.single .whr_embed_wrapper {
    float: right;
    width: 50%;
    padding-right: 10%;
    box-sizing: content-box;
}

/* Buttons */
.whr_enabled:not(.single) .jobs #whr_all_jobs_btn,
.whr_enabled:not(.single) .jobs #whr_apply_job_btn {
    display: none;
}

.whr_enabled #whr_all_jobs_btn,
.whr_enabled #whr_apply_job_btn {
    cursor: pointer;
    display: block;
}

.whr_enabled #whr_all_jobs_btn {
    color: #292d33;
    font-weight: bold;
}

.whr_enabled.single #whr_all_jobs_btn {
    float: left;
    width: 30%;
    /*margin-top: 0.6em;*/
    padding-right: 10%;
    box-sizing: content-box;
    cursor: default;
}

.whr_enabled.single #whr_all_jobs_btn > span {
    cursor: pointer;
}

.whr_enabled.single #whr_all_jobs_btn > span:hover .arrow-icon,
.whr_enabled.single #whr_all_jobs_btn > span:focus .arrow-icon {
    left: -3px;
}

.whr_enabled #whr_all_jobs_btn .arrow-icon {
    position: relative;
    left: 0;
    display: inline-block;
    vertical-align: top;
    width: 21px;
    height: 19px;
    margin: 0 1em 0 0;
    background: url("img/all-jobs-arrow.svg") center center no-repeat;
    -webkit-transition: 0.1s linear all;
    -moz-transition: 0.1s linear all;
    -o-transition: 0.1s linear all;
    transition: 0.1s linear all;
}

.whr_enabled #whr_apply_job_btn {
    color: #fff;
    font-weight: bold;
    font-size: 0.75em;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: rgba(242, 74, 74, 0.9);
    display: inline-block;
    padding: 15px 30px;
    margin: 60px 0 0 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
}

.whr_enabled #whr_apply_job_btn:hover,
.whr_enabled #whr_apply_job_btn:focus {
    background: #f24a4a;
}

.whr_enabled #whr_apply_job_btn.disabled,
.whr_enabled #whr_apply_job_btn.disabled:hover,
.whr_enabled #whr_apply_job_btn.disabled:focus {
    cursor: not-allowed;
    color: transparent;
    background: transparent;
}

.whr_enabled #whr_apply_job_btn.disabled {
    position: relative;
}

.whr_enabled #whr_apply_job_btn.disabled:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -16px 0 0 -16px;
    width: 32px;
    height: 32px;
    background-color: transparent;
    z-index: 1;
    background-image: url(img/loader_white.gif);
    background-repeat: no-repeat;
    background-position: center;
}

@media screen and (max-width: 480px) {
    .whr_enabled .job_listing_header,
    .whr_enabled.single #whr_all_jobs_btn,
    .whr_enabled.single .whr_embed_wrapper,
    .whr_enabled .jobs {
        float: none;
        width: auto;
        padding-right: 0;
        margin-bottom: 3em;
    }

    .whr_enabled.single {
        /*margin-top: -3rem;*/
        text-align: left;
    }
}

/* **** MWD overlay **** */
#mwd-overlay {
    /* display: none; */
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    margin: auto;
    color: rgba(0, 0, 0, 0.8);
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    text-transform: lowercase;
}

#mwd-overlay .mwd-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    /*margin: -0.5em 0 0 -0.6em;*/
    font-size: 5em;
    letter-spacing: 0.1em;
}

#mwd-overlay .rotateLinear {
    -webkit-animation: rotateLinear 1s infinite linear;
    -moz-animation: rotateLinear 1s infinite linear;
    -o-animation: rotateLinear 1s infinite linear;
}

@-webkit-keyframes rotateLinear {
    from {
        -webkit-transform: rotate(4deg) scale(1) skew(1deg) translate(10px);
    }
    to {
        -webkit-transform: rotate(360deg) scale(1) skew(1deg) translate(0px);
    }
}

@-moz-keyframes rotateLinear {
    from {
        -moz-transform: rotate(4deg) scale(1) skew(1deg) translate(10px);
    }
    to {
        -moz-transform: rotate(360deg) scale(1) skew(1deg) translate(0px);
    }
}

@-o-keyframes rotateLinear {
    from {
        -o-transform: rotate(4deg) scale(1) skew(1deg) translate(10px);
    }
    to {
        -o-transform: rotate(360deg) scale(1) skew(1deg) translate(0px);
    }
}

@media screen and (min-width: 481px) {
    .hidden-lg {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .hidden-xs {
        display: none;
    }


    .number-type-block,
    .currency-block,
    .plan-block {
        width: 100%;
        box-sizing: border-box;
        padding-top: 10px;
    }

    .prices-numbers-list .table.wrapper .number-type-block .currency-dropdown,
    .prices-numbers-list .table.wrapper .currency-block .currency-dropdown,
    .prices-numbers-list .table.wrapper .plan-block .currency-dropdown,
    .page-template-prices-international .table.wrapper .plan-block .currency-dropdown,
    .page-template-prices-international .table.wrapper .currency-block .currency-dropdown {
        position: relative;
        top: 0;
    }
    .prices-numbers-list .table.wrapper .currency-block .currency-dropdown {
        right: 0;
    }

    .more-option-links {
        margin-top: 10px;
        margin-bottom: 20px;
        clear: both;
    }

    .prices-numbers-list .table.wrapper table {
        margin-top: 20px;
    }

    .country-item {
        border: 1px solid rgba(215, 215, 215, 0.65);
        background: #F7FAFA;
        margin-bottom: 10px;
    }

    .prices-numbers-list .table.wrapper .country-item table {
        margin-top: 0;
        padding: 0.3rem 0;
    }

    .prices-international .table.wrapper .country-item td {
        padding: 0.4rem 0;
    }

    .country-item td:first-child {
        color: #a1a2b3;
    }

    .top.wrapper .content {
        text-align: left;
    }

    .price-panel-mob .sum {
        font-size: 1.4rem;
        line-height: 1rem;
        padding-top: 10px;
    }

    .prices-numbers .summary .small-links {
        padding: 0 1rem 1rem 1rem;
    }

    .prices-numbers .summary .inner .sum {
        font-size: 1.4rem;
        margin-top: 1rem;
    }

    .prices-numbers .summary .inner th, .prices-numbers .summary .inner td {
        padding: 0;
    }

    .prices-numbers .summary .inner h3 {
        display: inline-block;
        margin-top: 8px;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .price-panel-mob, .content .summary.summary-box {
        -webkit-transition: all 0.6s ease-in-out;
        -moz-transition: all 0.6s ease-in-out;
        transition: all 0.6s ease-in-out;
    }

    .price-panel-mob .icon.arrow {
        right: 25px;
    }

    .price-panel-mob .icon.closex {
        right: 20px;
    }

    .price-panel-mob {
        padding-right: 60px;
    }
}

@font-face {
    font-family: "Montserrat Medium";
    src: url("/wp-content/themes/sonetel/fonts/Montserrat-Medium.ttf");
}

b, strong {
    font-weight: 600;
}

/*body-home*/
.body-home {
    font-family: 'Montserrat Regular';
}

.body-home .container .news-header {
    color: #292D33;
    font-family: 'Montserrat Regular';
    font-size: 32px;
    line-height: 40px;
}

@media (min-width: 768px) and (max-width: 992px) {
    .body-home .container .news-header {
        text-align: center;
    }
}

.body-home .container .aticle-container {
    text-align: justify;
    text-align-last: justify;
}

@media (min-width: 768px) and (max-width: 992px) {
    .body-home .container .aticle-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.body-home .container .aticle-container article {
    display: inline-block;
    vertical-align: top;
    width: 373px;
}

@media (min-width: 993px) and (max-width: 1280px) {
    .body-home .container .aticle-container article {
        width: 32%;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .body-home .container .aticle-container article {
        width: 48%;
    }

    .body-home .container .aticle-container article .entry-excerpt p {
        margin-bottom: 48px;
    }
}

@media (min-width: 0px) and (max-width: 767px) {
    .body-home .container .aticle-container article {
        width: 100%;
    }
}

.body-home .container .aticle-container article .entry-thumb {
    display: block;
    padding-bottom: 61.66%;
    width: auto;
    text-align: center;
    text-align-last: center;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.body-home .container .aticle-container article .entry-category {
    margin-top: 24px;
    text-align: left;
    text-align-last: left;
    line-height: 10px;
}

.body-home .container .aticle-container article .entry-category a {
    color: #7C7C92;
    font-family: "Montserrat SemiBold";
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 10px;
    text-transform: uppercase;
    text-align: left;
    text-align-last: left;
}

.body-home .container .aticle-container article .entry-category a:hover, .body-home .container .aticle-container article .entry-category a:focus {
    color: #292D33;
}

.body-home .container .aticle-container article .entry-title {
    margin: 0px;
}

.body-home .container .aticle-container article .entry-title a {
    display: block;
    color: #292D33;
    font-family: "Montserrat Medium";
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    margin-top: 24px;
    margin-bottom: 8px;
    text-align: left;
    text-align-last: left;
}

.body-home .container .aticle-container article .entry-title a:focus, .body-home .container .aticle-container article .entry-title a:hover {
    color: #292D33;
}

.body-home .container .aticle-container article .entry-excerpt {
    margin-top: 0px;
}

.body-home .container .aticle-container article .entry-excerpt p {
    color: #7C7C92;
    font-size: 17px;
    line-height: 25px;
    margin-top: 0px;
    margin-bottom: 40px;
    text-align: left;
    text-align-last: left;
}

/*body-home end*/
/*blog*/
.blog .top.wrapper .hero.container {
    height: calc(100vh - 66px);
    display: flex;
    align-items: center;
    padding-top: 0;
    min-height: initial;
}

.blog .top.wrapper .hero.container .content .category-name {
    text-align: left;
    color: #FFFFFF;
    font-family: "Montserrat SemiBold";
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 10px;
    text-transform: uppercase;
}

.blog .top.wrapper .hero.container .content .slide-title {
    text-align: left;
    margin-top: 24px;
    max-width: 566px;
}

.blog .top.wrapper .hero.container .content .slide-title a {
    text-align: left;
    color: #FFFFFF;
    font-family: "Montserrat SemiBold";
    font-size: 56px;
    font-weight: 600;
    line-height: 64px;
}

.blog #featured-current-slide {
    min-height: 100vh;
}

.blog #featured-current-slide .slide-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.blog .featured.wrapper {
    border-bottom: 0px solid #e7e9ee;
    background: none;
    position: relative;
    top: -122px;
    height: 122px;
}

.blog .featured.wrapper .line {
    box-sizing: border-box;
    height: 1px;
    background-color: #FFFFFF;
    opacity: 0.38;
    position: relative;
    z-index: 0;
}

.blog .featured.wrapper .gallery-wrap {
    position: relative;
    z-index: 1;
    top: -1px;
}

.blog .featured.wrapper .gallery-wrap .owl-item:first-child .item {
    text-align: left;
}

.blog .featured.wrapper .gallery-wrap .item {
    text-align: center;
    position: relative;
}

.blog .featured.wrapper .gallery-wrap .item .featured-item {
    cursor: pointer;
    padding-top: 41px;
}

.blog .featured.wrapper .gallery-wrap .item .featured-item .line-bold {
    position: absolute;
    top: 0px;
    left: 50%;
    width: 0%;
    transition: all 0.3s;
}

.blog .featured.wrapper .gallery-wrap .item .featured-item .current {
    left: 0%;
    box-sizing: border-box;
    height: 2px;
    border: 2px solid #FFFFFF;
    opacity: 0.38;
    width: 100%;
}

.blog .featured.wrapper .gallery-wrap .item .featured-item span {
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 8px;
    box-sizing: border-box;
    height: 25px;
    width: 25px;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    color: #fff;
    vertical-align: top;
    line-height: 25px;
    font-family: "Montserrat SemiBold";
    font-size: 14px;
    text-align: center;
}

@media (min-width: 1281px) {
    .blog .featured.wrapper .gallery-wrap .item .featured-item span {
        display: inline-block;
        margin-right: 16px;
    }
}

.blog .featured.wrapper .gallery-wrap .item .featured-item .featured-item-title {
    display: inline-block;
    height: 40px;
    max-width: 226px;
    color: #FFFFFF;
    font-family: "Montserrat SemiBold";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    vertical-align: top;
    padding: 0px;
    text-align: center;
}

@media (min-width: 1281px) {
    .blog .featured.wrapper .gallery-wrap .item .featured-item .featured-item-title {
        text-align: left;
    }
}

.blog .featured.wrapper .gallery-wrap .item .featured-item:hover .line-bold:not(.current) {
    /*left: 0%;
							box-sizing: border-box;
							height: 0px;
							border: 0px solid #FFFFFF;
							width: 100%;*/
    display: none !important;
}

.blog .featured.wrapper .gallery-wrap .owl-theme .owl-dots {
    display: none;
}

.blog .featured.wrapper .gallery-wrap .owl-theme .owl-nav {
    display: none;
}

.blog .featured.wrapper .gallery-wrap .owl-theme .owl-nav > div {
    position: absolute;
    z-index: 10;
    top: 35px;
    width: 24px;
    height: 44px;
    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
}

.blog .featured.wrapper .gallery-wrap .owl-theme .owl-nav .owl-prev {
    left: -40px;
}

@media (max-width: 1300px) {
    .blog .featured.wrapper .gallery-wrap .owl-theme .owl-nav .owl-prev {
        left: -30px;
    }
}

@media (max-width: 1199px) {
    .blog .featured.wrapper .gallery-wrap .owl-theme .owl-nav .owl-prev {
        left: -35px;
    }
}

@media (max-width: 992px) {
    .blog .featured.wrapper .gallery-wrap .owl-theme .owl-nav .owl-prev {
        left: -10px;
    }
}

.blog .featured.wrapper .gallery-wrap .owl-theme .owl-nav .owl-next {
    right: -40px;
}

@media (max-width: 1300px) {
    .blog .featured.wrapper .gallery-wrap .owl-theme .owl-nav .owl-next {
        right: -30px;
    }
}

@media (max-width: 1199px) {
    .blog .featured.wrapper .gallery-wrap .owl-theme .owl-nav .owl-next {
        right: -35px;
    }
}

@media (max-width: 992px) {
    .blog .featured.wrapper .gallery-wrap .owl-theme .owl-nav .owl-next {
        right: -10px;
    }
}

@media (max-width: 1300px) {
    .blog .featured.wrapper .gallery-wrap {
        width: 100%;
        margin: 0 auto;
    }
}

.blog .featured.wrapper .line-bold {
    display: none;
    position: absolute;
    top: 0px;
    left: 0;
    width: 0;
    height: 2px;
    box-sizing: border-box;
    border: 2px solid #FFFFFF;
    -webkit-transition: width 0.3s, left 0.3s;
    transition: width 0.3s, left 0.3s;
}

.blog .featured.wrapper .line-bold.current {
    display: block;
}

.blog .nav-blog-categories {
    margin-top: -122px;
}

.blog .block-link {
    background: #fff;
    border-bottom: solid 1px #e7e7e7;
    z-index: 1000;
    width: 100%;
}

.blog .block-link .togle-button {
    display: none;
}

.blog .block-link .mobile-menu-block {
    display: none;
}

.blog .block-link .menu-block {
    display: flex;
    align-items: center;
    height: 48px;
}

.blog .block-link .menu-block .menu {
    list-style-type: none;
}

.blog .block-link .menu-block .menu li {
    display: inline-block;
}

.blog .block-link .menu-block .menu li a {
    padding-right: 32px;
    line-height: 11px;
    text-transform: uppercase;
    font-family: "Montserrat Medium";
    font-family: "Montserrat SemiBold";
    font-size: 10px;
    font-weight: 600;
    line-height: 10px;
    letter-spacing: 2px;
    color: #7C7C92;
}

.blog .block-link .menu-block .menu li.active a {
    color: #292D33;
}

.blog .block-link .menu-block .menu li:hover a, .blog .block-link .menu-block .menu li:focus a {
    color: #292D33;
}

.blog .posts {
    text-align: justify;
    text-align-last: left;
    display: flex;
    flex-wrap: wrap;
}

@media (min-width: 993px) and (max-width: 1280px) {
    .blog .posts {
        justify-content: space-between;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .blog .posts {
        justify-content: space-between;
    }
}

.blog .posts.container {
    padding-top: 121px;
}

.blog .posts.container:before {
    position: absolute;
}

.blog .posts article {
    display: inline-block;
    vertical-align: top;
    width: 374px;
    margin-right: 26px;
}

.blog .posts article:nth-child(3n+3) {
    margin-right: 0;
}

@media (min-width: 993px) and (max-width: 1280px) {
    .blog .posts article {
        width: 32%;
        margin-right: 0;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .blog .posts article {
        width: 49%;
        margin-right: 0;
    }

    .blog .posts article .entry-excerpt p {
        margin-bottom: 48px;
    }
}

@media (min-width: 0px) and (max-width: 767px) {
    .blog .posts article {
        margin-right: 0;
        width: 100%;
    }
}

.blog .posts article .entry-thumb {
    display: block;
    padding-bottom: 61.66%;
    width: auto;
    text-align: center;
    text-align-last: center;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    margin: 0;
    /*&:hover{
					padding: 0px;
					transition: all 0.7s;
					a{
						box-shadow: 0 16px 28px 0 rgba(0,0,0,0.51);
					}
				}*/
}

.blog .posts article .entry-category {
    margin-top: 24px;
    text-align: left;
    text-align-last: left;
    line-height: 10px;
}

.blog .posts article .entry-category a {
    color: #7C7C92;
    font-family: "Montserrat SemiBold";
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 10px;
    text-transform: uppercase;
    text-align: left;
    text-align-last: left;
}

.blog .posts article .entry-category a:hover, .blog .posts article .entry-category a:focus {
    color: #292D33;
}

.blog .posts article .entry-title {
    margin: 0px;
}

.blog .posts article .entry-title a {
    display: block;
    color: #292D33;
    font-family: "Montserrat Medium";
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    margin-top: 25px;
    margin-bottom: 8px;
    text-align: left;
    text-align-last: left;
}

.blog .posts article .entry-title a:focus, .blog .posts article .entry-title a:hover {
    color: #292D33;
}

.blog .posts article .entry-excerpt {
    margin-top: 0px;
}

.blog .posts article .entry-excerpt p {
    color: #7C7C92;
    font-size: 17px;
    line-height: 25px;
    margin-top: 0px;
    margin-bottom: 40px;
    margin-bottom: 97px;
    text-align: left;
    text-align-last: left;
}

@media (min-width: 0px) and (max-width: 767px) {
    .blog .posts article .entry-excerpt p {
        margin-bottom: 48px;
    }
}

.blog .block-link.fixed-menu {
    z-index: 99999;
    max-width: 1680px;
    position: fixed;
    top: 0px;
}

.archive .article-element .entry-thumb, .archive .post .entry-thumb, .home .article-element .entry-thumb, .home .post .entry-thumb, .blog .article-element .entry-thumb, .blog .post .entry-thumb {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -ms-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
    overflow: visible !important;
}

.archive .article-element .entry-thumb:hover {
    transform: scale(1);
    box-shadow: none;
}

@media (min-width: 1680px) {
    .blog .block-link.fixed-menu {
        z-index: 99999;
        width: 1679px;
        max-width: 1680px;
        position: fixed;
        top: 0px;
        left: 50%;
        margin-left: -847px;
        overflow: hidden;
    }
}

@media screen and (max-width: 767px) {
    .blog .top.wrapper .hero.container .content div {
        font-size: 10px;
    }

    .blog .block-link {
        height: 64px;
        position: relative;
        padding: 0;
    }

    .blog .block-link .mobile-menu-block {
        padding: 0 2rem;
        display: none;
        width: 100%;
        position: absolute;
        z-index: 1000;
        top: 64px;
        background: #fff;
        border-bottom: solid 1px #e7e7e7;
        -webkit-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.21);
        -moz-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.21);
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.21);
    }

    .blog .block-link .mobile-menu-block .menu {
        list-style-type: none;
    }

    .blog .block-link .mobile-menu-block .menu li {
        display: block;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .blog .block-link .mobile-menu-block .menu li a {
        color: #a3a3b4;
        padding-right: 32px;
        padding-right: 32px;
        font-family: "Montserrat Regular";
        font-family: "Montserrat SemiBold";
        font-size: 11px;
        font-weight: 300;
        letter-spacing: 2px;
        line-height: 11px;
        text-transform: uppercase;
    }

    .blog .block-link .mobile-menu-block .menu li:first-child {
        padding-top: 20px;
    }

    .blog .block-link .mobile-menu-block .menu li:last-child {
        padding-bottom: 38px;
    }

    .blog .block-link .mobile-menu-block .menu li.active a {
        color: #292d33;
    }

    .blog .block-link .mobile-menu-block.visible {
        display: block;
    }

    .blog .block-link .togle-button {
        padding: 0 2rem;
        cursor: pointer;
        display: none;
        color: #292d33;
        font-family: "Montserrat SemiBold";
        font-size: 11px;
        font-weight: 300;
        letter-spacing: 2px;
        line-height: 11px;
        text-transform: uppercase;
        padding-top: 29px;
        padding-bottom: 24px;
    }

    .blog .block-link .menu-block {
        display: none;
    }

    .blog .posts {
        text-align: center;
        text-align-last: center;
    }
}

@media screen and (max-width: 480px) {
    .blog .top.wrapper {
        min-height: 540px;
    }

    .blog .block-link {
        display: none;
    }

    .blog .block-link .container {
        display: none;
    }

    .blog .block-link .mobile-menu-block {
        padding: 0 1rem;
    }

    .blog .block-link .togle-button {
        padding-left: 1rem;
    }

    .blog-breadcrumbs {
        margin: 0 -2rem !important;
        width: 102vw !important;
    }

    .blog-breadcrumbs > .top-navigation {
        margin: 0 2em !important;
    }
}

@media screen and (max-width: 450px) {
    .blog .featured.wrapper {
        top: -52px;
    }

    .blog .featured.wrapper .line {
        display: none;
    }

    .blog .featured.wrapper .owl-carousel .owl-stage-outer .owl-stage,
    .blog .featured.wrapper .line-bold,
    .blog .featured.wrapper .line-bold.current {
        visibility: hidden;
        display: none;
    }

    .blog .featured.wrapper .gallery-wrap .item .featured-item .line-bold {
        display: none;
    }

    .blog .featured.wrapper .gallery-wrap .owl-theme .owl-dots {
        display: block;
        margin: 0 auto;
    }

    .blog .featured.wrapper .gallery-wrap .owl-theme .owl-nav {
        display: none;
    }

    .blog .featured.wrapper .gallery-wrap .owl-theme .owl-dots .owl-dot span {
        border-radius: 30px;
        height: 8px;
        width: 8px;
        opacity: 0.24;
        background-color: #FFFFFF;
    }

    .blog .featured.wrapper .gallery-wrap .owl-theme .owl-dots .owl-dot.active span,
    .blog .featured.wrapper .gallery-wrap .owl-theme .owl-dots .owl-dot:hover span {
        background-color: #FFFFFF;
        opacity: 1;
    }
}

/*blog end*/
/*blog-topic*/
.blog-topic .top.wrapper {
    border-bottom: solid 1px #e7e7e7;
    background: #ffffff;
    padding-bottom: 1.5rem;
}

.blog-topic .top.wrapper div.nav ul.menu > li > a {
    font-family: "Montserrat Medium";
}

.blog-topic .top-nav-menu.fixed {
    position: fixed;
    top: 0;
    z-index: 1000;
}

.blog-topic .article.container {
    font-family: "Montserrat Regular";
    padding-top: 0px;
}

.blog-topic .article.container .top-navigation span a {
    padding-right: 10px;
    color: #a3a3b4;
    font-family: "Montserrat";
    font-family: "Montserrat Regular";
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.blog-topic .article.container .top-navigation span a:hover {
    color: #292D33;
}

.blog-topic .article.container .top-navigation span a:last-child {
    padding-left: 7px;
}

.blog-topic .article.container .top-navigation .sprite-arraw-grey {
    padding-left: 9px;
    padding-right: 0px;
    position: relative;
}

.blog-topic .article.container .block-top {
    padding-top: 88px;
}

.blog-topic .article.container .block-top .mobile-aside-block {
    display: none;
}

.blog-topic .article.container .block-top .aside-block {
    overflow: hidden;
    display: block;
    float: left;
    width: 298px;
}

.blog-topic .article.container .block-top .aside-block .aside-menu {
    margin: 0px;
    padding: 0px;
}

.blog-topic .article.container .block-top .aside-block .aside-menu li {
    list-style-type: none;
    line-height: 33px;
}

.blog-topic .article.container .block-top .aside-block .aside-menu li a {
    color: #a3a3b4;
    font-size: 17px;
    font-weight: 400;
    line-height: 21px;
}

.blog-topic .article.container .block-top .aside-block .aside-menu li a:hover {
    color: #292D33;
}

.blog-topic .article.container .block-top .aside-block .aside-menu li.current-cat a {
    color: #292d33;
    font-weight: 500;
    font-family: "Montserrat Medium";
}

.blog-topic .article.container .block-top .article-block {
    display: block;
    margin-left: 300px;
    max-width: 673px;
}

.blog-topic .article.container .block-top .article-block .article-element {
    padding-bottom: 65px;
    margin-bottom: 63px;
    border-bottom: 1px solid #EEEEEE;
}

.blog-topic .article.container .block-top .article-block .article-element .right {
    float: right;
    width: 175px;
}

.blog-topic .article.container .block-top .article-block .article-element .right a {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 230px;
    display: block;
}

@media (min-width: 0px) and (max-width: 767px) {
    .blog-topic .article.container .block-top .article-block .article-element .right a {
        height: 135px;
    }
}

.blog-topic .article.container .block-top .article-block .article-element .left {
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
}

.blog-topic .article.container .block-top .article-block .article-element .left h1 {
    padding-bottom: 0px;
    margin-bottom: 0px;
    line-height: 48px;
}

.blog-topic .article.container .block-top .article-block .article-element .left h1 a {
    font-family: "Montserrat Regular";
    color: #292D33;
    font-size: 40px;
    line-height: 48px;
}

.blog-topic .article.container .block-top .article-block .article-element .left a p {
    color: #7C7C92;
    font-family: "Montserrat Regular";
    font-size: 17px;
    line-height: 25px;
    margin: 16px 0px 0px 0px;
}

.blog-topic .article.container .block-top .article-block .article-element .left .info {
    font-size: 17px;
    line-height: 24px;
    font-size: 20px;
    line-height: 32px;
    margin-top: 23px;
}

.blog-topic .article.container .block-top .article-block .article-element .left .info .aktier {
    color: #1f2226;
    font-weight: 500;
    font-family: "Montserrat Medium";
}

.blog-topic .article.container .block-top .article-block .article-element .left .info .amount {
    color: #7e7e94;
    font-weight: 400;
}

.blog-topic .article.container .block-top .article-block .article-element .left .entry-category {
    margin-top: 10px;
}

.blog-topic .article.container .block-top .article-block .article-element .left .entry-category a {
    color: #7C7C92;
}

.blog-topic .article.container .block-top .article-block .article-element .left .entry-category a:hover, .blog-topic .article.container .block-top .article-block .article-element .left .entry-category a:focus {
    color: #292D33;
}

.blog-topic .article.container .block-top .article-block .article-element.has-post-thumbnail .left {
    margin-right: 175px;
    padding-left: 0px;
    padding-right: 26px;
}

.blog-topic .article.container .block-top .article-block .article-element:after {
    display: block;
    content: "";
    clear: both;
}

@media screen and (max-width: 992px) {
    .blog-topic .article.container .block-topic .aside-block {
        width: 198px;
    }

    .blog-topic .article.container .block-topic .article-block {
        margin-left: 200px;
    }
}

@media screen and (max-width: 767px) {
    .blog-topic .top.wrapper {
        padding-bottom: 0.7rem;
    }

    .blog-topic .top.wrapper .nav {
        padding-top: 0.75rem;
        padding-bottom: 0;
    }

    .blog-topic .article.container .block-top {
        padding-top: 0px;
    }

    .blog-topic .article.container .block-top .top.wrapper div.nav div.logo a:last-child {
        margin-top: 2px;
    }

    .blog-topic .article.container .block-top .mobile-aside-block {
        display: block;
        float: none;
        position: relative;
        margin: 0 -2rem;
        width: 100vw;
    }
}

@media screen and (max-width: 767px) and (max-width: 480px) {
    .blog-topic .article.container .block-top .mobile-aside-block {
        width: 102vw;
    }
}

@media screen and (max-width: 767px) {
    .blog-topic .article.container .block-top .mobile-aside-block .togle-button {
        cursor: pointer;
        padding: 9px 2rem;
        border-bottom: solid 1px #e7e7e7;
    }

    .blog-topic .article.container .block-top .mobile-aside-block .togle-button .down-class {
        float: right;
        position: relative;
        top: 13px;
        right: 0;
    }

    .blog-topic .article.container .block-top .mobile-aside-block .togle-button .close {
        float: right;
        position: relative;
        top: 10px;
        right: 0px;
        width: 14px;
        height: 14px;
        background-size: contain;
    }

    .blog-topic .article.container .block-top .mobile-aside-block .togle-button .togle-button-text {
        float: left;
        font-size: 17px;
    }

    .blog-topic .article.container .block-top .mobile-aside-block .togle-button .togle-button-text:hover {
        color: #292D33;
    }

    .blog-topic .article.container .block-top .mobile-aside-block .togle-button:hover {
        color: #292D33;
    }

    .blog-topic .article.container .block-top .mobile-aside-block .togle-button:after {
        display: block;
        content: '';
        clear: both;
    }

    .blog-topic .article.container .block-top .mobile-aside-block .mobile-menu-block {
        display: none;
        position: absolute;
        z-index: 1000;
        background: #fff;
        border-bottom: solid 1px #e7e7e7;
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.21);
        box-shadow: 0 3px 4px rgba(90, 90, 90, 0.1);
        width: 100%;
        top: 46px;
    }

    .blog-topic .article.container .block-top .mobile-aside-block .mobile-menu-block .visible {
        display: block;
    }

    .blog-topic .article.container .block-top .mobile-aside-block .mobile-menu-block .aside-menu {
        margin: 0px;
        padding: 0px;
    }

    .blog-topic .article.container .block-top .mobile-aside-block .mobile-menu-block .aside-menu li {
        list-style-type: none;
        line-height: 33px;
        padding: 0 2rem;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .blog-topic .article.container .block-top .mobile-aside-block .mobile-menu-block .aside-menu li a {
        color: #a3a3b4;
        font-size: 17px;
        font-weight: 400;
        line-height: 21px;
    }

    .blog-topic .article.container .block-top .mobile-aside-block .mobile-menu-block .aside-menu li a.active {
        color: #292d33;
        font-weight: 300;
    }

    .blog-topic .article.container .block-top .mobile-aside-block .mobile-menu-block .aside-menu li.current-cat, .blog-topic .article.container .block-top .mobile-aside-block .mobile-menu-block .aside-menu li:hover, .blog-topic .article.container .block-top .mobile-aside-block .mobile-menu-block .aside-menu li:focus {
        background: #fff;
    }

    .blog-topic .article.container .block-top .mobile-aside-block .mobile-menu-block .aside-menu li.current-cat a, .blog-topic .article.container .block-top .mobile-aside-block .mobile-menu-block .aside-menu li:hover a, .blog-topic .article.container .block-top .mobile-aside-block .mobile-menu-block .aside-menu li:focus a {
        color: #292D33;
    }

    .blog-topic .article.container .block-top .mobile-aside-block .mobile-menu-block .aside-menu li.current-cat {
        /*border-bottom: 1px solid #e7e7e7;*/
        display: none;
    }

    .blog-topic .article.container .block-top .mobile-aside-block .mobile-menu-block .aside-menu li.current-cat a {
        font-weight: 700;
    }

    .blog-topic .article.container .block-top .mobile-aside-block:after {
        display: block;
        content: '';
        clear: both;
    }

    .blog-topic .article.container .block-top .aside-block {
        display: none;
        float: none;
    }

    .blog-topic .article.container .block-top .article-block {
        margin-left: 0px;
        float: none;
        width: 100%;
        padding-top: 65px;
    }
}

@media screen and (max-width: 480px) {
    .blog-topic .article.container .block-top .top.wrapper {
        padding-top: 5px;
        padding-bottom: 0;
        height: 64px;
    }

    .blog-topic .article.container .block-top .top.wrapper div.nav {
        padding-top: 6px;
        padding-bottom: 10px;
        height: 64px;
    }

    .blog-topic .article.container .block-top .top.wrapper div.nav div.logo a:last-child {
        margin-top: 3px;
    }

    .blog-topic .article.container .block-top .article-block .article-element {
        padding-bottom: 47px;
        margin-bottom: 48px;
    }

    .blog-topic .article.container .block-top .article-block .article-element .right {
        width: 100px;
    }

    .blog-topic .article.container .block-top .article-block .article-element .left h1 {
        padding-bottom: 0px;
        margin-bottom: 0px;
        line-height: 34px;
    }

    .blog-topic .article.container .block-top .article-block .article-element .left h1 a {
        color: #292D33;
        font-family: "Montserrat Medium";
        font-size: 24px;
        font-weight: 500;
        line-height: 34px;
    }

    .blog-topic .article.container .block-top .article-block .article-element .left a p {
        margin: 8px 0px 0px 0px;
        color: #7C7C92;
        font-family: "Montserrat Regular";
        font-size: 15px;
        line-height: 22px;
    }

    .blog-topic .article.container .block-top .article-block .article-element.has-post-thumbnail .left {
        margin-right: 100px;
        padding-right: 10px;
    }
}

/*blog-topic end*/
/*blog-posts*/
.blog-posts .top.wrapper {
    border-bottom: solid 1px #e7e7e7;
    background: #ffffff;
    padding-bottom: 1.5rem;
}

.blog-posts .top.wrapper div.nav ul.menu > li > a {
    font-family: "Montserrat Medium";
}

@media screen and (max-width: 767px) {
    .blog-posts .top.wrapper {
        padding-bottom: 0.7rem;
    }
}

.blog-posts .article.container {
    font-family: "Montserrat Regular";
    padding-top: 0px;
}

.blog-posts .article.container .top-navigation span a {
    padding-right: 10px;
    color: #a3a3b4;
    font-family: "Montserrat";
    font-family: "Montserrat Regular";
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.blog-posts .article.container .top-navigation span a:hover {
    color: #292D33;
}

.blog-posts .article.container .top-navigation span a:last-child {
    padding-left: 7px;
}

.blog-posts .article.container .top-navigation .sprite-arraw-grey {
    padding-left: 9px;
    padding-right: 0px;
    position: relative;
}

.blog-posts .article.container .article-block article .author-block, .blog-posts .article.container #entry-related-posts article .author-block {
    padding-top: 97px;
    padding-bottom: 48px;
}

@media (min-width: 0px) and (max-width: 767px) {
    .blog-posts .article.container .article-block article .author-block, .blog-posts .article.container #entry-related-posts article .author-block {
        padding-top: 68px;
    }
}

.blog-posts .article.container .article-block article .author-block .author-avatar, .blog-posts .article.container #entry-related-posts article .author-block .author-avatar {
    display: inline-block;
    height: 72px;
    width: 72px;
    overflow: hidden;
    margin-left: 10px;
    margin-right: 15px;
    vertical-align: top;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
}

@media (min-width: 0px) and (max-width: 767px) {
    .blog-posts .article.container .article-block article .author-block .author-avatar, .blog-posts .article.container #entry-related-posts article .author-block .author-avatar {
        margin-left: 0;
    }
}

.blog-posts .article.container .article-block article .author-block .author-info, .blog-posts .article.container #entry-related-posts article .author-block .author-info {
    display: inline-block;
    height: 72px;
}

.blog-posts .article.container .article-block article .author-block .author-info .author-name, .blog-posts .article.container #entry-related-posts article .author-block .author-info .author-name {
    color: #363B42;
    font-family: "Montserrat SemiBold";
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
}

.blog-posts .article.container .article-block article .author-block .author-info .author-description, .blog-posts .article.container #entry-related-posts article .author-block .author-info .author-description {
    color: #7C7C92;
    font-family: "Montserrat Regular";
    font-size: 15px;
    line-height: 22px;
    margin-top: 3px;
    margin-bottom: -5px;
}

.blog-posts .article.container .article-block article .author-block .author-info time, .blog-posts .article.container #entry-related-posts article .author-block .author-info time {
    color: #7C7C92;
    font-family: "Montserrat Regular";
    font-size: 15px;
    line-height: 22px;
}

.blog-posts .article.container .article-block article header h1, .blog-posts .article.container #entry-related-posts article header h1 {
    color: #292D33;
    font-family: "Montserrat Regular";
    font-size: 48px;
    line-height: 56px;
    padding-bottom: 24px;
    margin: 0;
}

.blog-posts .article.container .article-block article header .entry-excerpt, .blog-posts .article.container #entry-related-posts article header .entry-excerpt {
    color: #7C7C92;
    font-family: "Montserrat Regular";
    font-size: 24px;
    line-height: 35px;
    margin: 0;
}

.blog-posts .article.container .article-block article header .entry-thumb, .blog-posts .article.container #entry-related-posts article header .entry-thumb {
    height: 768px;
    margin: 120px 0 125px 0;
}

@media (min-width: 0px) and (max-width: 767px) {
    .blog-posts .article.container .article-block article header .entry-thumb, .blog-posts .article.container #entry-related-posts article header .entry-thumb {
        margin: 96px 0;
        height: 540px;
    }
}

.blog-posts .article.container .article-block article header .entry-thumb a, .blog-posts .article.container #entry-related-posts article header .entry-thumb a {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: block;
    max-width: 1680px;
    width: 100%;
    height: 768px;
    position: absolute;
    left: 0;
    right: 0;
}

@media (min-width: 0px) and (max-width: 767px) {
    .blog-posts .article.container .article-block article header .entry-thumb a, .blog-posts .article.container #entry-related-posts article header .entry-thumb a {
        height: 540px;
    }
}

.blog-posts .article.container .article-block article .entry-content, .blog-posts .article.container #entry-related-posts article .entry-content {
    position: relative;
}

.blog-posts .article.container .article-block article .entry-content h3, .blog-posts .article.container #entry-related-posts article .entry-content h3 {
    color: #292D33;
    font-family: "Montserrat Regular";
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 16px;
    margin-left: 1px;
}

.blog-posts .article.container .article-block article .entry-content p, .blog-posts .article.container #entry-related-posts article .entry-content p {
    color: #7C7C92;
    font-family: "Montserrat Regular";
    font-size: 20px;
    line-height: 33px;
    margin-top: 0;
    margin-bottom: 35px;
}

.blog-posts .article.container .article-block article .entry-content img, .blog-posts .article.container #entry-related-posts article .entry-content img {
    margin: 0px auto;
}

@media (min-width: 0px) and (max-width: 767px) {
    .blog-posts .article.container .article-block article .entry-content img, .blog-posts .article.container #entry-related-posts article .entry-content img {
        margin: 0px auto;
    }
}

.blog-topic-scroll .fixed-panel {
    position: fixed !important;
    top: 0px;
    width: 100%;
    max-width: 1680px;
    background: #fff;
    z-index: 1000;
}

.blog-topic-scroll .stub-breadcrumbs {
    height: 48px;
    background: transparent;
    display: none;
}

.blog-topic-scroll .parent-breadcrumbs .stub-breadcrumbs {
    display: block;
}

.entry-content {
    position: relative;
}

.entry-content .sharing-call-btn {
    color: #A3A3B4;
    font-family: "Montserrat Regular";
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 14px;
    border: none;
    background: transparent;
    text-align: right;
    display: block;
    margin-left: auto;
    text-transform: uppercase;
    position: relative;
    top: 28px;
}

.entry-content .sharing-call-btn:before {
    content: url("img/blog/share-icon.svg");
    margin-right: 3px;
    height: 16px;
    width: 12px;
    display: inline-block;
    margin-right: 13px;
    vertical-align: sub;
}

.entry-content .sharing-call-btn .social-share-wrapper {
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.11);
    padding: 18px 20px;
    position: absolute;
    top: -16px;
    left: -16px;
    transform: translateY(-100%);
    z-index: 10;
    display: none;
    background-color: #fff;
}

.entry-content .sharing-call-btn .social-share-wrapper:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    bottom: -10px;
    left: 25px;
    box-sizing: border-box;
    border: 5px solid transparent;
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    box-shadow: -1px 2px 2px 0 rgba(0, 0, 0, 0.11);
    z-index: -10;
}

.entry-content .sharing-call-btn .social-share-link {
    font-family: "Montserrat Regular";
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    color: #292D33;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-bottom: 15px;
    text-transform: none;
}

.entry-content .sharing-call-btn .social-share-link:last-child {
    margin-bottom: 0;
}

.entry-content .sharing-call-btn .social-share-icon {
    display: block;
    width: 12px;
    height: 12px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 15px;
}

.entry-content .sharing-call-btn .sh-fb-icon {
    background-image: url("img/blog/fb_logo.svg");
}

.entry-content .sharing-call-btn .sh-tw-icon {
    background-image: url("img/blog/twitter_logo.svg");
}

.entry-content .sharing-call-btn .sh-gp-icon {
    background-image: url("img/blog/google_logo.svg");
}

.entry-content .sharing-call-btn .sh-in-icon {
    background-image: url("img/blog/lin_sh.svg");
}

.entry-content .addthis_toolbox {
    margin: 0 !important;
    padding: 0;
    position: absolute;
    display: none;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.11);
    right: -65px;
    bottom: 35px;
    min-width: 172px;
    z-index: 100;
    background: #fff;
    padding-left: 17px;
    padding-top: 16px;
}

.entry-content .addthis_toolbox a {
    display: block;
    float: none;
    margin: 0;
    padding: 0;
    color: #292D33;
    font-family: "Montserrat";
    font-size: 12px;
    line-height: 16px;
    height: 16px;
    margin-bottom: 15px;
}

.entry-content .addthis_toolbox a:hover, .entry-content .addthis_toolbox a:focus {
    color: #000;
}

.entry-content .addthis_toolbox a span {
    width: 12px !important;
    height: 12px !important;
    margin-right: 12px;
    background: transparent !important;
}

.entry-content .addthis_toolbox a span svg {
    display: none;
}

.entry-content .addthis_toolbox a[class^=addthis_button_] span {
    background: url("img/flags/glo.png") no-repeat center center !important;
}

.entry-content .addthis_toolbox a.addthis_button_facebook span {
    background: url("img/blog/fb_logo.svg") no-repeat center center !important;
}

.entry-content .addthis_toolbox a.addthis_button_twitter span {
    background: url("img/blog/twitter_logo.svg") no-repeat center center !important;
}

.entry-content .addthis_toolbox a.addthis_button_google_plusone_share span {
    background: url("img/blog/google_logo.svg") no-repeat center center !important;
}

.entry-content .addthis_toolbox a.addthis_button_linkedin span {
    background: url("img/blog/lin_sh.svg") no-repeat center center !important;
}

.entry-content .addthis_toolbox a.addthis_counter {
    display: none !important;
}

.entry-content .addthis_toolbox:after {
    content: '';
    border: 7px solid transparent;
    border-top: 7px solid #fff;
    position: absolute;
    left: 20%;
}

@media (min-width: 0px) and (max-width: 767px) {
    .entry-content .addthis_toolbox {
        right: 5px;
    }

    .entry-content .addthis_toolbox:after {
        left: 60%;
    }

    body .SnapABug_Button {
        left: auto !important;
        right: 50px;
    }
}

/* ***** Algolia Search **** */
.search.container h1 {
    font-family: "Montserrat Regular";
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    margin: 40px;
}

.search.container #popular-search-query-list {
    float: none;
    padding: 0;
    margin: 0 auto;
    max-width: 571px;
    width: 100%;
    color: #7C7C92;
    font-family: "Montserrat Regular";
    font-size: 15px;
    line-height: 33px;
    text-align: center;
}

.search.container #popular-search-query-list a {
    text-decoration: underline;
}

/* ***** /Algolia Search **** */
/* Dirty Overrides :( */
.archive .blog-topic .article.container .block-top {
    padding-top: 84px;
}

@media (min-width: 0px) and (max-width: 767px) {
    .archive .blog-topic .article.container .block-top {
        padding-top: 0;
    }
}

.archive .top-navigation1 .top-navigation .article span:first-child a {
    padding-left: 0;
}

@media (min-width: 0px) and (max-width: 767px) {
    .archive .top-navigation1 .top-navigation .article span:first-child a {
        padding-left: 7px;
    }
}

.archive .article.container ol li, .archive .article.container ul li {
    margin-top: 0;
}

.archive .blog-topic .article.container .block-top .article-block {
    padding-top: 11px;
    max-width: 770px;
}

@media (min-width: 0px) and (max-width: 767px) {
    .archive .blog-topic .article.container .block-top .article-block {
        padding-top: 63px;
    }
}

.archive .sprite-arraw-grey-down {
    width: 13px;
    height: 13px;
    background-size: contain;
}

.single .article.container .content {
    width: 100%;
    margin: 0 auto;
}

.single .article.container .article-block .entry-excerpt p {
    margin-top: 0;
}

.single .article.container strong {
    color: #292D33;
}

.sh-post__headline {
    padding-bottom: 0 !important;
}

.sh-post__intro {
    font-size: 24px;
    color: #7C7C92;
    line-height: 35px;
}

.sh-post__hero-image {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: block;
    max-width: 1680px;
    width: 100%;
    height: 768px;
    position: absolute;
    left: 0;
    right: 0;
}

@media (min-width: 0px) and (max-width: 767px) {
    .sh-post__hero-image {
        height: 540px;
    }
}

.sh-post__content--text {
    max-width: 666px;
    margin: 0 auto;
}

.sh-post__block-text {
    max-width: 666px;
    margin: 0 auto;
}

.sh-post__image {
    max-width: 967px;
    margin: 96px auto;
}

@media (min-width: 0px) and (max-width: 767px) {
    .sh-post__image {
        margin: 96px -24px;
    }
}

.sh-post__section__title {
    margin-bottom: 22px;
    color: #292D33;
}

.sh-post__section__text {
    font-size: 20px;
    line-height: 33px;
    color: #7C7C92;
}

.sh-post__section__text p {
    margin-bottom: 33px;
}

.sh-post__footer {
    max-width: 666px;
    margin: 35px auto 64px auto;
}

.sh-post__footer .sharing-call-btn {
    color: #A3A3B4;
    font-family: "Montserrat Regular";
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 14px;
    border: none;
    background: transparent;
    text-align: right;
    display: block;
    margin-left: auto;
    text-transform: uppercase;
    position: relative;
    top: 28px;
    padding: 0;
}

.sh-post__footer .sharing-call-btn:before {
    content: url("img/blog/share-icon.svg");
    margin-right: 3px;
    height: 16px;
    width: 12px;
    display: inline-block;
    margin-right: 13px;
    vertical-align: sub;
}

.social-share-wrapper {
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.11);
    padding: 18px 20px;
    position: absolute;
    top: -16px;
    left: -22px;
    transform: translateY(-100%);
    z-index: 10;
    display: none;
    background-color: #fff;
}

@media (min-width: 0px) and (max-width: 767px) {
    .social-share-wrapper {
        left: auto;
        right: 0;
    }
}

.social-share-wrapper:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    bottom: -10px;
    left: 25px;
    box-sizing: border-box;
    border: 5px solid transparent;
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    box-shadow: -1px 2px 2px 0 rgba(0, 0, 0, 0.11);
    z-index: -10;
}

@media (min-width: 0px) and (max-width: 767px) {
    .social-share-wrapper:after {
        left: auto;
        right: 58px;
    }
}

.social-share-link {
    font-family: "Montserrat Regular";
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    color: #292D33;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-bottom: 15px;
    text-transform: none;
}

.social-share-link:last-child {
    margin-bottom: 0;
}

.social-share-icon {
    display: block;
    width: 12px;
    height: 12px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 15px;
}

.sh-fb-icon {
    background-image: url("img/blog/fb_logo.svg");
}

.sh-tw-icon {
    background-image: url("img/blog/twitter_logo.svg");
}

.sh-gp-icon {
    background-image: url("img/blog/google_logo.svg");
}

.sh-in-icon {
    background-image: url("img/blog/lin_sh.svg");
}

.ellip {
    display: block;
    height: 100%;
}

.ellip-line {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    max-width: 100%;
}

.ellip,
.ellip-line {
    position: relative;
    overflow: hidden;
}

.body {
    width: 100%;
}

.summary__wrapper {
    border: 1px solid #EAEAEA;
    padding: 40px;
}

.summary__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ECEEF1;
    margin-bottom: 32px;
    padding-bottom: 23px;
}

.summary__header h3 {
    font-family: 'Montserrat Regular';
    font-size: 24px;
    line-height: 36px;
    color: #24232B;
    margin: 0;
}

.summary__content__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    font-size: 16px;
    line-height: 36px;
    color: #89909A;
}

.summary__content__row.summary-price {
    font-family: 'Montserrat Medium';
    color: #24232B;
}

.summary__cta {
    margin-top: 70px;
}

.summary__cta .cta-button.big {
    width: 100%;
    height: 48px;
    border-radius: 3px;
    font-family: 'Montserrat Medium';
    font-size: 16px;
    line-height: 22px;
    text-transform: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.summary .currency-dropdown {
    border: none;
    position: relative;
    right: auto;
}

.summary .currency-dropdown div span {
    background-image: none;
    padding: 0;
}

.summary .currency-dropdown__item {
    display: flex;
    align-items: center;
    font-family: 'Montserrat Medium';
    font-size: 14px;
    line-height: 14px;
    color: #292D33;
}

.footer .summary .currency-dropdown__item {
    padding: 10px 0px 5px;
}

.footer .summary .currency-dropdown__flag {
    margin-right: 12px;
}

.summary .currency-dropdown div span {
    color: #7e7e94;
    font-size: 15px;
    font-weight: 500;
}

.summary .currency-dropdown ul {
    padding: 0px 0px 4px;
}

.footer .summary .currency-menu .currency-dropdown__item {
    padding: 5px 1px 5px;
}


.summary .currency-dropdown__flag {
    display: block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
}

.summary .currency-dropdown__flag--usd {
    background-image: url("img/flags/us.png");
}

.summary .currency-dropdown__flag--sek {
    background-image: url("img/flags/se.png");
}

.summary .currency-dropdown__flag--eur {
    background-image: url("img/flags/eu.png");
}

.summary .currency-dropdown ul {
    position: absolute;
    width: 100%;
    z-index: 10;
    background-color: transparent;
}

.summary .currency-dropdown.period-dropdown {
    height: 48px;
    width: 100%;
    border: 1px solid #D7DCE4;
    border-radius: 3px;
    background-color: #fff;
    font-family: 'Montserrat Medium';
    font-size: 16px;
    line-height: 24px;
    color: #24232B;
    position: relative;
}

.summary .currency-dropdown.period-dropdown div {
    padding: 0;
}

.summary .currency-dropdown.period-dropdown__list {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    border: 1px solid rgba(215, 215, 215, 0.6);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

.summary .currency-dropdown.period-dropdown__list__option {
    background-color: #fff;
    height: 48px;
    text-align: left;
    padding: 13px 40px 13px 16px !important;
}

.summary .currency-dropdown.period-dropdown__list__option:hover {
    background-color: #f8f9fC !important;
}

.summary .dropdown {
    height: 48px;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #D7DCE4;
    margin-bottom: 17px;
    background-color: #ffffff;
}

.summary .dropdown .input {
    border: none;
}

.summary .dropdown input {
    font-family: 'Montserrat Medium';
    font-size: 16px;
    line-height: 24px;
    color: #24232B;
    padding: 13px 40px 11px 16px;
}

.summary .dropdown .button {
    width: 40px;
    border: none;
}

.summary .dropdown .button .icon {
    background-size: 9px 4px;
}

.summary .dropdown .list {
    top: 48px;
}

.summary .dropdown .list li {
    height: 48px;
    display: flex;
    align-items: center;
}

.summary__links {
    margin-top: 13px;
}

.summary__links.small-links {
    padding: 0;
}

.summary__links.small-links a, .summary__links.small-links a.help-link {
    font-family: 'Montserrat Medium';
    font-size: 14px;
    line-height: 19px;
    color: #89909A;
    text-decoration: none !important;
    transition: text-decoration 0.2s;
}

.summary__links.small-links a:hover, .summary__links.small-links a.help-link:hover {
    text-decoration: underline;
}

.period-block {
    height: 100%;
}

.plan-block {
    height: 100%;
}

.summary .period-dropdown {
    margin-bottom: 16px;
}

.summary .period-dropdown__select {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 13px 40px 13px 16px !important;
}

.summary .period-dropdown__select:after {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url("img/arrow-down-icon.svg");
    background-size: 11px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    right: 13px;
    transform: translateY(-50%);
}

.summary .period-dropdown__list {
    position: absolute;
    bottom: 0;
    left: -1px;
    width: calc(100% + 2px) !important;
    transform: translateY(100%);
    border: 1px solid rgba(215, 215, 215, 0.6);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

.summary .period-dropdown__list__option {
    background-color: #fff;
    height: 48px;
    padding: 13px 40px 13px 16px !important;
}

.summary .period-dropdown__list__option:hover {
    background-color: #f8f9fC !important;
}

.full-list .currency-dropdown {
    width: 5rem;
}

.full-list .currency-dropdown.period-dropdown {
    right: 18rem;
    width: 11rem;
    font-size: 1rem;
}

.full-list .currency-dropdown.plan-dropdown {
    right: 0;
    width: 11rem;
    font-size: 1rem;
}

.full-list .currency-dropdown.period-dropdown div {
    padding: 0.8rem 1rem 0.8rem 1rem;

}

.full-list .currency-dropdown.plan-dropdown div {
    padding: 0.8rem 1rem 0.8rem 1rem;
}

.full-list .currency-dropdown.period-dropdown div span {
    display: inline-block;
    width: 100%;
    padding: 0;
}

.full-list .currency-dropdown.plan-dropdown div span {
    display: inline-block;
    width: 100%;
    padding: 0;
}

.full-list .currency-dropdown.period-dropdown li {
    padding: 0.8rem 1rem 0.8rem 1rem;
    background-color: #fff;
}

.full-list .currency-dropdown.plan-dropdown li {
    padding: 0.8rem 1rem 0.8rem 1rem;
    background-color: #fff;
}

.full-list .currency-dropdown.period-dropdown li:hover {
    background-color: #fff;
}

.full-list .currency-dropdown.plan-dropdown li:hover {
    background-color: #fff;
}

.period-table-wrapper {
    text-align: left !important;
}

.page-template-prices-international .info-button {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: 10px;
    background-image: url('img/info-icon.svg');
    background-size: 20px 20px;
    opacity: 0.25;
    @include transition(all 0.2s);
    .no-touch &:hover, &.active {
        opacity: 1;
    }
}

.price-info-xs {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.price-info-lg {
    display: flex;
    align-items: center;
}

.tooltipPrice {
    position: absolute;
    right: 0;
    background: #ffffff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    text-align: left;
    width: 100%;
    max-width: 400px;
    margin-top: 17px;
    z-index: 9999;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    font-size: 14px;
    color: rgb(36, 35, 43);
    font-weight: 500;
}

.tooltip-header {
    display: flex;
    align-items: center;
    font-size: 16px;
    justify-content: center;
    padding: .9rem 2.5rem;
}

.tooltip-line {
    background-color: rgba(151, 151, 151, 0.22);
    height: 1px;
}

.tooltip-content {
    padding: .9rem 2.5rem;
}






@media screen and (max-width: 32em) {
    .tooltipPrice { font-size: 12px; }
    .tooltip-header { font-size: 14px; }
}


@media screen and (max-width: 1100px) {
    .summary .currency-dropdown__item {color: #7e7e94;}
}