.jobs {
	.top.wrapper {
		background-image: url('img/page-headers/jobs.png');
	}

	.image {
		height: rem(640);

		@include media($mobile) {
			height: rem(240);
		}
	}

	.listing.wrapper {
		background-color: $light-bg-color;
		padding-top: rem(128);
		padding-bottom: rem(128);

		@include media($mobile) {
			padding-top: rem(64);
			padding-bottom: rem(64);

			text-align: center;
		}

		.content {
			@include span-columns(10);
			@include shift(1);

			@include media($mobile) {
				@include span-columns(12);
				@include shift(0);
			}

			.jobs {
				margin-top: rem(56);
			}

			.job {
				@include span-columns(5 of 10);
				@include omega(2n);

				color: $medium-font-color;

				margin-bottom: rem(48);

				h4 {
					color: $dark-font-color;
					margin-bottom: rem(4);
				}

				p:last-child {
					margin-bottom: 0;
				}

				@include media($mobile) {
					@include span-columns(12);

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}