.layout-part.layout-contact-cta {
	

	text-align: center;

	.container {
		padding-top: rem(72);
		padding-bottom: rem(64);
	}

	h2 {
		text-align: center;
	}
	
	.phone {
		margin-top: rem(16);

		a {
			color: $dark-font-color;
			text-decoration: underline;
		}
	}

	/*
	.content {
		@include span-columns(10);
		@include shift(1);

		.post {
			@include span-columns(5 of 10);
			@include omega(2n);

			margin-bottom: rem(56);

			@include media($mobile) {
				@include span-columns(4);
				margin-bottom: rem(36);
			}
		}
	}
	*/
}