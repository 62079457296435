.home {
	.top.wrapper {
		
		.announcement {
			@include fill-parent;

			left: 0;
			right: 0;

			position: absolute;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.12);
			text-align: center;
			padding: 0.75rem 1rem;

			color: #fff;

			@include media($mobile) {
				padding: rem(8);
				p {
					margin: 0;
				}
			}
		}
	}
}