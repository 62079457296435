.faq.wrapper {
	padding-top: rem(72);

	h2 {
		@include span-columns(10);
		@include shift(1);
		text-align: layout-direction();
		margin-bottom: rem(16);
	}

	.small-thick-hr {
		display: block;
		margin-bottom: rem(48);
	}

	.content {
		@include span-columns(10);
		@include shift(1);

		.post {
			@include span-columns(5 of 10);
			@include omega(2n);

			margin-bottom: rem(56);

			@include media($mobile) {
				@include span-columns(12);
				margin-bottom: rem(36);
			}

			color: $medium-font-color;

			h4 {
				color: $dark-font-color;
			}
		}
	}
}