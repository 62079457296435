.service {

	.top.wrapper {
		background-color: $dark-bg-color;

		.background-image {
			opacity: 0.1;
		}
	}

	.benefits-features.wrapper {
		padding-top: rem(200);
		padding-bottom: rem(200);

		@include media($mobile) {
			padding-top: rem(88);
			padding-bottom: rem(88);
		}

		background-color: $light-bg-color;

		.background {
			position: absolute;
			width: 50%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: $light-bg-color-hover;
			z-index: 0;

			@include media($mobile) {
				width: 100%;
			}
		}

		.container {
			position: relative;
			z-index: 1;
		}

		.content > div {
			@include span-columns(5);

			&.column-2 {
				@include shift(2);
			}

			@include media($mobile) {
				@include span-columns(12);
				text-align: center;

				&.column-1 {
					margin-bottom: rem(64);
				}

				&.column-2 {
					@include shift(0);
				}
			}
		}

		/*
		@include media($mobile) {
			padding-top: rem(64);
			padding-bottom: rem(64);

			.column-1 {
				margin-bottom: rem(64);
			}
		}

		.column-1, .column-2 {
			@include span-columns(5 of 10);

			@include media($mobile) {
				@include span-columns(4);
				text-align: center;
			}
		}

		.column-2 {
			@include shift(2);

			@include media($mobile) {
				@include shift(0);
			}
		}
		*/

		h2 {
			margin-bottom: 0;
		}

		h4 {
			margin-top: rem(50);
			color: $dark-font-color;
		}
	}

	.pricing.wrapper {
		padding-top: rem(424);
		padding-bottom: rem(216);
		background-color: $dark-bg-color;

		@include media($mobile) {
			padding-top: rem(88);
			padding-bottom: rem(88);
		}

		color: #fff;

		.background-image {
			opacity: 0.1;
		}

		.left-column {
			@include span-columns(4);

			@include media($mobile) {
				@include span-columns(12);
			}

			position: relative;

			h2 {
				color: #fff;
				margin-bottom: rem(16);
			}

			h3 {
				color: $medium-font-color;
				margin-top: 0;
				margin-bottom: rem(40);
				font-weight: normal;

				@include media($mobile) {
					margin-bottom: rem(8);
				}
			}

			div.text {
				@include span-columns(3 of 4);

				@include media($mobile) {
					@include span-columns(12);
				}
			}
		}

		.right-column {
			@include span-columns(8);

			@include media($mobile) {
				@include span-columns(12);
			}

			position: relative;

			padding-top: rem(32);

			table {
				width: 100%;

				@include media($mobile) {
					font-size: rem(12);
				}

				thead {
					border-bottom: 1px solid rgba(255, 255, 255, 0.1);
					white-space: nowrap;

					th {
						font-size: rem(24);
					}
				}

				tr:first-child {
					th, td {
						padding-top: rem(32);
						padding-right: 25px;
						vertical-align: initial;
						padding-bottom: 10px;
						@include media($mobile) {
							font-size: rem(12);
						}
					}
				}

				th, td {
					text-align: left;
					padding: rem(16) 0;
					padding-right: 45px;

					@include media($mobile) {
						padding: rem(8);
					}

					&:first-child {
						padding-left: 0;
					}

					&:last-child {
						padding-right: 0;
					}
				}
			}
		}
	}
	.video {
		//@include span-columns(8 of 12);

		text-align: center;

		iframe {
			width: 100%;
			display: inline-block;
		}
		padding-bottom: rem(72);
	}
}
