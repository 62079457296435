div.nav, div.language-footer {
	//@include span-columns(12);
	//@include omega();
	//background-color: rgba(255, 0, 0, 0.2);

	position: relative;
	padding-top: rem(24);

	@include media($mobile) {
		padding-top: rem(16);
		padding-bottom: rem(16);
	}

	div.logo {
		//@include span-columns(4);
		float: layout-direction();

		//line-height: 0;

		a:first-child {
			display: inline-block;
			width: rem(123);
			height: rem(36);
			background-size: rem(123) rem(36);

			.dark-top & {
				background-image: url('img/header/logo-light.svg');
			}

			.light-top &, 
			.gray-top & {
				background-image: url('img/header/logo-dark.svg');
			}
		}

		a:last-child {
			display: inline-block;
			line-height: rem(32);
			margin-top: rem(4);
			font-size: rem(16);
			vertical-align: top;
			color: $dark-font-color;
		}

		div.separator {
			display: inline-block;
			margin: 0 rem(16);
			width: 1px;
			height: rem(36);
			background-color: $dark-font-color;
		}
	}

	.mobile-menu-button {
		float: opposite-layout-direction();
		display: none;

		@include media($mobile1100) {
			display: inline-block;
		}

		width: rem(38);
		height: rem(40);
		
		background-repeat: no-repeat;
		background-position: center;

		.dark-top & {
			background-image: url('img/icons/hamburger-light.svg');
		}

		.light-top & {
			background-image: url('img/icons/hamburger-dark.svg');
		}
	}

	ul.menu {
		float: opposite-layout-direction();
		text-align: opposite-layout-direction();
		margin-top: 8px;

		@include media($mobile) {
			display: none;
		}

		> li {
			display: inline-block;
			margin: 0 16px;
			position: relative;
			float: layout-direction();

			&:last-child {
				margin-right: 0;
			}

			> a, > div.toggle {
				cursor: pointer;
				line-height: rem(32);
				display: inline-block;
				/* font-family: $demi-font; */
				font-size: rem(12);
				text-decoration: none;
				text-transform: uppercase;

				letter-spacing: 2px;
				
				.dark-top & {
					color: #fff;
				}

				.light-top &,
				.gray-top & {
					color: $dark-font-color;
				}

				&.try-free-button {
					display: block;
					margin-top: -4px;
					padding: rem(2) rem(16) 0 rem(16);

					.dark-top & {
						color: #fff;
						border: 2px solid #fff;
					}

					.dark-top &:hover {
						color: $dark-font-color;
						background-color: #fff;
					}

					.light-top &,
					.gray-top & {
						color: $red;
						border: 2px solid $red;
					}

					.light-top &:hover,
					.gray-top &:hover {
						color: #fff;
						background-color: $red;
						border: 2px solid $red;
					}
				}
			}
		}
	}

	div.flag-icon {
		display: inline-block;
		width: 16px;
		height: 16px;
		background-color: #000;
		border-radius: 8px;
		vertical-align: middle;

		background-size: cover;
		background-position: 25%;
	}

	ul.language-menu {
		position: absolute;
		right: -16px;
		background-color: $lang-menu-bg-color;
		text-align: left;
		margin-top: 8px;
		z-index: 9999;

		//display: none;
		@include transition(opacity 0.5s);

		&.hidden {
			opacity: 0;
			visibility: hidden;

			@include transition(opacity 0.2s);
		}

		&:after {
			bottom: 100%;
			right: 16px;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: transparent;
			border-bottom-color: $lang-menu-bg-color;
			border-width: 8px;
			margin-left: -8px;
		}

		li {
			a {
				//font-family: $lang-menu-font;
				color: $lang-menu-link-color;
				white-space: nowrap;
				padding: 0 45px 0 12px;
				line-height: 36px;
				display: inline-block;
				font-size: 12px;
				text-decoration: none;

				div.flag-icon {
					margin-right: 16px;
				}

				&:hover {
					color: #fff;
				}
			}

			border-bottom: 1px solid $lang-menu-separator-color;

			&:last-child {
				border-bottom: none;
			}
		}
		body.rtl & {
			text-align: right;

			li a {
				padding: 0 12px 0 45px;
				div.flag-icon {
					margin-right: 0;
					margin-left: 16px;
				}
			}
		}
	}
}

div.language-footer {
	padding-top: 3px;
	list-style-type: none;

	div.toggle {
		font-size: 15px;
		font-weight: 600;
	}

	div.flag-icon {
		vertical-align: text-bottom;
		margin-bottom: 1px;
		margin-right: 11px;
	}

	ul.language-menu {
		right: unset;
		&:after {
			right: unset;
			left: 40px;
		}

	}
}
