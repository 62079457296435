.prices-international {

	.top.wrapper {
		padding-bottom: rem(72);
	}

	.subheader {
		margin-bottom: rem(32);
		color: $medium-font-color;
	}

	.dropdown {
		@include span-columns(6);
		@include shift(3);

		@include media($mobile) {
			@include span-columns(12);
			@include shift(0);
		}

		text-align: left;
		background-color: #fff;
	}

	.table.wrapper {
		padding-top: rem(128);
		padding-bottom: rem(128);

		@include media($mobile) {
			padding-top: rem(96);
			padding-bottom: rem(32);
		}

		color: $dark-font-color;

		.loading {
			text-align: center;
		}

		.content {
			@include span-columns(10);
			@include shift(1);

			@include media($mobile) {
				@include span-columns(12);
				@include shift(0);
			}
		}

		table {
			width: 100%;
		}

		th {
			border-bottom: 1px solid $hr-color;
		}

		th, td {
			text-align: right;
			font-weight: normal;
			padding: rem(16) 0;
		}

		th:first-child, td:first-child {
			text-align: left;
		}

		.show-all-countries {
			float: right;
			padding: rem(16) 0;
		}
	}
}
.rtl.prices-international {
	.dropdown {
		text-align: right;
	}
	.table.wrapper {
		th:first-child,
		td:first-child {
			text-align: right;
			unicode-bidi: bidi-override !important;
		}
	}
}

.page-template-bring-old-numbers {
	.table.wrapper {
		padding-top: rem(64);
		padding-bottom: rem(64);
		table {
			margin-top: rem(64);
			tr {
				&:nth-child(2n) {
					background: #F8F9FC;
				}
				&.even {
					background: none;
				}
				&.odd {
					background: #F8F9FC;
				}

			}
			th, td {
				padding-left: rem(16);
				padding-right: rem(16);

			}
			th {
				color: #a1a2b3;
				border-bottom: 1px solid $hr-color;
			}
		}
	}
}

