@font-face {
  font-family: 'Montserrat SemiBold';
  src: url('fonts/Montserrat-SemiBold.eot'); /* IE9 Compat Modes */
  src: url('fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/Montserrat-SemiBold.woff') format('woff'), /* Modern Browsers */
       url('fonts/Montserrat-SemiBold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('fonts/Montserrat-SemiBold.svg#9b4c7bb97a15852a038674d2e7642256') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'Montserrat Regular';
  src: url('fonts/Montserrat-Regular.eot'); /* IE9 Compat Modes */
  src: url('fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/Montserrat-Regular.woff') format('woff'), /* Modern Browsers */
       url('fonts/Montserrat-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('fonts/Montserrat-Regular.svg#fa20c367c6a148cda65f50238befb5f2') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'Montserrat Medium';
  src: url('fonts/Montserrat-Medium.eot'); /* IE9 Compat Modes */
  src: url('fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/Montserrat-Medium.woff') format('woff'), /* Modern Browsers */
       url('fonts/Montserrat-Medium.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'Montserrat ExtraLight';
  src: url('fonts/Montserrat-ExtraLight.eot'); /* IE9 Compat Modes */
  src: url('fonts/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/Montserrat-ExtraLight.woff') format('woff'), /* Modern Browsers */
       url('fonts/Montserrat-ExtraLight.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('fonts/Montserrat-ExtraLight.svg#1a5065e0e284a0405138f180cca15589') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  400;
}

