.yarpp-related {
	margin: 0 !important;
}

.related.wrapper {
	
	@include media($mobile) {
		border-bottom: 1px solid $hr-color;
	}

	.related.container {
		padding-top: 0;
		padding-bottom: rem(128);

		@include media($mobile) {
			padding-bottom: rem(64);
		}
		
		.content {
			@include span-columns(8);
			@include shift(2);

			hr {
				margin-bottom: rem(64);
			}

			li {
				@include span-columns(4 of 8);
				@include omega(2n);
				margin-bottom: rem(16);
			}

			@include media($mobile) {
				@include span-columns(12);
				@include shift(0);

				li {
					@include span-columns(12);
					//@include omega(0);
				}
			}

			@include media($tablet-portrait) {
				@include span-columns(10);
				@include shift(1);
			}
		}
	}
}