/*!
Theme Name: Sonetel (source)
Theme URI: http://wordpress.org/themes/twentyfourteen
Author: Sebastian Carlsson
Author URI: http://sebastiancarlsson.se/
Description: This is a custom Wordpress theme developed for the Sonetel website.
Version: 1.0
*/

// Libs
@import "bourbon/bourbon";
@import "variables"; // Variables for fonts, colours, etc. Imported before Neat to customise grid
@import "neat/neat";
@import "normalize";

@import "media-queries";

// Site specific
@import "functions";
@import "fonts"; // Font declarations
@import "flags"; // Flags
// Page specific styles
@import "nav"; // Top nav
@import "mobile-nav"; // Top nav
@import "top"; // Styles for hero content just below the nav bar
@import "home"; // Home page
@import "services"; // Service pages
@import "help"; // Support pages
@import "jobs"; // Jobs page
@import "about"; // About page
@import "contact"; // Contact page
@import "system-status"; // Contact page
@import "blog"; // Blog page
@import "partners"; // Partners page
@import "404"; // 404 page
@import "sign-up"; // sign up
@import "prices"; // prices
@import "prices-numbers"; // prices numbers
@import "prices-international"; // prices international
@import "footer";
// Import layout partials
@import "sub-page-menu"; // Sub page menu
@import "article"; // Article
@import "related"; // Related posts
@import "breadcrumbs"; // Breadcrumbs nav
@import "search-box"; // Search box
@import "dropdown";
@import "currency-dropdown";
@import "slider";
@import "layouts/layouts";
@import "elements"; // Global elements such as <html>, <h1>, wrappers, buttons, etc
@import "todos";