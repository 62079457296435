.search-box {
	position: relative;
	height: rem(64);
	line-height: rem(64);
	
	.input {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: rem(64);

		input {
			width: 100%;
			border: 1px solid #dcdcdc;
			border-right: 0;
			height: rem(64);
			line-height: rem(64);
			padding: rem(20) rem(24);

			@include media($mobile) {
				padding: rem(8);
			}
		}
	}

	.button {
		position: absolute;
		cursor: pointer;
		width: rem(64);
		height: rem(64);
		background-color: $red;
		right: 0;
		background-image: url('img/search-icon.svg');
		background-repeat: no-repeat;
		background-position: center;
	}

	.results {
		display: none;

		&.open {
			display: block;
		}

		position: absolute;
		z-index: 1;
		top: rem(64);
		width: 100%;
		background-color: #dcdcdc;
		border: 1px solid #dcdcdc;
		border-top: none;

		text-align: left;
		line-height: rem(44);

		max-height: rem(300);
		overflow-y: auto;

		.title, .loading, .no-matches {
			background-color: #fff;

			font-size: rem(11);
			text-transform: uppercase;
			//font-family: $demi-font;
			letter-spacing: rem(1);
			color: $light-font-color;
			line-height: rem(32);
		}

		.loading, .no-matches {
			padding: rem(16) rem(24) rem(8) rem(24);
		}

		.matches {
			padding: rem(16) rem(24) rem(8) rem(24);
			background-color: #fff;
			//display: none;

			a {
				display: block;

				&.selected,
				&:hover {
					background-color: $light-bg-color;
				}
			}

			ul li  {
				a {
					padding-left: 0;
				}

				ul li a {
					padding-left: 0;
					display: inline-block;
					height: 100%;
					vertical-align: top;
				}
			}

			.subtopics {
				//margin-left: rem(24);
				padding-left: rem(16);
				list-style-type: disc;
				list-style-position: outside;

				li {
					margin-left: 0;
					line-height: rem(28);
					padding-bottom: rem(16);
					
				}
			}
		}
	}
}