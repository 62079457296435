.currency-dropdown {

	//background-color: #fff;
	display: inline-block;
	border: 1px solid $hr-color;
	position: absolute;
	right: rem(24);
	cursor: pointer;
	font-size: rem(12);

	@include media($mobile) {
		right: rem(16);
	}

	div {
		padding: rem(6) rem(10) rem(4) rem(10);

		span {
			background-image: url('img/small-arrow-down-icon.svg');
			background-size: 9px 5px;
			background-repeat: no-repeat;
			background-position: center right;
			//background-color: #f00;

			padding-top: rem(3);
			padding-right: rem(14);
		}
	}

	ul {
		background-color: $light-bg-color;

		li {
			padding: rem(6) rem(10) rem(4) rem(10);

			.rtl & {
				padding-right: rem(24);
			}
			&:hover {
				background-color: #fff;
			}

			.rtl & {
				padding-right: rem(24);
			}
		}
	}

}